import React, { Fragment, MouseEvent } from 'react';
import { connect } from 'react-redux';
import { IAppState } from '../../store';
import { RouteComponentProps } from 'react-router-dom';
import { donationRequestActions } from '../../requestActions';
import {
  defaultCurrency,
  handleInputChange,
  formatCurrency,
  toastSuccess,
  routes,
  swapRouteParams,
  errorHelpers,
  toastError,
  typeHelpers,
} from '../../helpers';
import {
  ICampaignFund,
  ITransactionSummary,
  IBulkActionError,
  IUser,
  IAddressBase,
} from '@gigit/interfaces';
import { IGroupState } from '../../reducers/group';
import { createToast } from '../../actions/toaster';
import {
  downloadGroupDonationReceipt,
  sendConfirmationEmail,
  getDonationsExport,
  getPledgesExport,
  createGroupDonationIntent,
  cancelGroupRecurringDonation,
} from '../../actions/group';
import {
  getEventTeams,
  getEventIndividuals,
  createEventDonationIntent,
  getDonationsExportEvent,
  downloadEventDonationReceipt,
} from '../../actions/event';
import { IEventState } from '../../reducers/event';
import Modal from '../Modal/Modal';
import Button from '../Button/Button';
import TextField from '../../components/TextField/TextField';
import './DonationList.scss';
import { IOwnerObject, ISortSettings, IToast, ReduxActionType } from '../../interfaces';
import { LocaleDateFormats, localizeHelpers } from '../../localizeHelpers';
import { userSelectors } from '../../selectors/user';
import { uiConstants } from '../../constants/uiConstants';
import axios from 'axios';
import { Constants } from '@gigit/constants';
import { DonationListExpandedCell } from './DonationListExpandedCell/DonationListExpandedCell';
import { EditThankYouMessage } from './DonationListModals/EditThankYouMessage/EditThankYouMessage';
import { SelectPayment } from './DonationListModals/SelectPayment/SelectPayment';
import { BulkErrors } from './DonationListModals/BulkErrors/BulkErrors';
import { GenerateAndEditTaxReceipt } from './DonationListModals/GenerateTaxReceipt/GenerateAndEditTaxReceipt';
import DonationDestinationModal from '../DonationDestinationModal/DonationDestinationModal';
import { Prompt } from '../Prompt/Prompt';
import Table, { ITableProps } from '../shared/Table/Table';
import { ITableAction } from '../shared/Table/tableState';
import ReconcilePayment from './DonationListModals/ReconcilePayment/ReconcilePayment';
import AddDonation from '../AddDonation/AddDonation';
import {
  IssuedTaxReceiptsExportModal,
  TaxReceiptExportType,
} from '../TransactionManagement/IssuedTaxReceiptsExportModal/IssuedTaxReceiptsExportModal';

interface IProps extends RouteComponentProps<any> {
  groupState: IGroupState;
  eventState: IEventState;
  createToast(toast: IToast): void;
  getPledgesExport: ReduxActionType<typeof getPledgesExport>;
  sendConfirmationEmail(groupId: string, _id: string): void;
  downloadGroupDonationReceipt: ReduxActionType<typeof downloadGroupDonationReceipt>;
  downloadEventDonationReceipt: ReduxActionType<typeof downloadEventDonationReceipt>;
  getDonationsExport(groupId: string, groupHandle: string): void;
  getDonationsExportEvent(eventId: string, eventHandle: string): void;
  getEventTeams(eventId: string): void;
  getEventIndividuals(eventId: string): void;
  createGroupDonationIntent(groupId: string, _payload: any, callback?: any): void;
  createEventDonationIntent(eventId: string, _payload: any, groupId?: string, callback?: any): void;
  cancelGroupRecurringDonation(eventId: string, transactionId: string): void;
  owner: IOwnerObject;
  locale: string;
  contact?: {
    user?: IUser;
    location?: IAddressBase;
  };
  // Used to force tables to refresh after manually adding record.
  refreshHandle: object | null;
}

interface IState {
  showReconcilePaymentModal: boolean;
  donations: ITransactionSummary[];
  showRefundModal: boolean;
  refund_amount: number | string;
  showEditModal: boolean;
  order: ITransactionSummary | null;
  showThankYouModal: boolean;
  donationType: string;
  campaignFunds: { label: string; value: string }[];
  donationAmount: number;
  otherAmount: string;
  comments: string;
  anonymous: boolean;
  sendReceipt: boolean;
  dedicate: boolean;
  selectedDedicate: string;
  hFirst: string;
  hLast: string;
  rFirst: string;
  rLast: string;
  rEmail: string;
  rMessage: string;
  dPrefix: string;
  formRef: React.RefObject<unknown>;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  gender: string;
  address: string;
  apartment: string;
  city: string;
  selectedProvince: string;
  postal: string;
  donateTo: string;
  selectedTeam: string;
  selectedIndividual: string;
  teams: { label: string; value: string }[];
  isFundraiser: boolean;
  showSelectPaymentModal: boolean;
  selectedPaymentMethod: string;
  searchTimeout: null | ReturnType<typeof setTimeout>;
  country: string;
  selectedItems: string[];
  bulkActionLoading: boolean;
  showBulkErrors: boolean;
  bulkErrorMessages: string[];
  sort: ISortSettings[];
  activeDonation: ITransactionSummary | null;
  cardBrand: string;
  last4Digits: string;
  showGenerateAndEditModal: boolean;
  fullDonationRefund: boolean;
  changeDonationDestination: ITransactionSummary | null;
  currentPage: number;
  hasMore: boolean;
  onceTableConfig: ITableProps<ITransactionSummary>;
  onceRefreshTableIncrementor: number;
  recurringTableConfig: ITableProps<ITransactionSummary>;
  recurringRefreshTableIncrementor: number;
  recurringPaymentToCancel: boolean;
  paymentIdToCancel: string;
  donationSubscriptions: ITransactionSummary[];
  donationsLoading: boolean;
  showAddDonation: boolean;
  exportTaxReceiptsAction: TaxReceiptExportType | null;
}

class DonationList extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      showAddDonation: false,
      exportTaxReceiptsAction: null,
      showReconcilePaymentModal: false,
      recurringTableConfig: {
        filterOptions: {
          enableColumnSorting: true,
          enableTableSearch: true,
          enableFilterDrawer: true,
          filterDrawerOptions: [
            {
              type: 'textField',
              fieldId: 'user.display_name',
              options: { label: 'Donor' },
            },
            {
              type: 'textField',
              fieldId: 'amounts.amount',
              options: { label: 'Amount' },
            },
          ],
        },
        columns: [
          {
            id: 'user.display_name',
            sortable: true,
            Header: 'Donor',
            Cell: (donation) => {
              return <span notranslate="yes">{donation.user.display_name}</span>;
            },
            accessor: (donation) => donation.user.display_name,
          },
          {
            id: 'amounts.amount',
            sortable: true,
            Header: 'Amount',
            Cell: (donation) => {
              const exchangeRate = donation.reporting_exchange_rate ?? 1;
              const currency = donation.reporting_currency ?? defaultCurrency;
              const amount =
                donation.amounts.amount * (donation.amounts?.amount_multiplier ?? 1) * exchangeRate;
              return (
                <var data-var="donation_amounts_amount">
                  {formatCurrency(amount, currency, this.props.locale)}
                </var>
              );
            },
            accessor: (donation) => donation.amounts.amount,
          },
          {
            id: 'created_at',
            sortable: true,
            Header: 'First Donation',
            accessor: (donation) => donation.created_at?.toString(),
            Cell: (donation) => {
              return (
                <span notranslate="yes">
                  {localizeHelpers.formatDate(
                    donation.created_at ?? new Date(),
                    LocaleDateFormats.LL,
                    this.props.locale,
                  )}
                </span>
              );
            },
          },
          {
            id: 'account.subscription.last_donation.created_at',
            sortable: true,
            Header: 'Last Donation',
            accessor: (donation) =>
              donation.account?.subscription?.last_donation?.created_at?.toString(),
            Cell: (donation) => {
              return (
                <span notranslate="yes">
                  {localizeHelpers.formatDate(
                    donation.account?.subscription?.last_donation?.created_at ?? new Date(),
                    LocaleDateFormats.LL,
                    this.props.locale,
                  )}
                </span>
              );
            },
          },
        ],
        pagination: {
          pageSizeOptions: [10],
          queryAction: async (params) => await this.getData(params),
        },
        emptyStateConfig: {
          title: 'No data',
          description: "We couldn't find any donations",
        },
        tableActionOptions: {
          enableRowContextMenuActions: true,
          tableActions: [
            {
              type: 'ROW_CONTEXT_MENU',
              icon: 'fa fa-trash',
              onClick: (e: MouseEvent<HTMLElement>, donation) => {
                this.setState({
                  paymentIdToCancel: donation.id ? donation.id : '',
                  recurringPaymentToCancel: true,
                });
              },
              label: 'Cancel Recurring Payment',
            },
          ],
          rowDetailDrawerOptions: (donation: ITransactionSummary) => ({
            title: '',
            renderContent: () => <DonationListExpandedCell donation={donation} />,
          }),
        },
      },
      recurringRefreshTableIncrementor: 0,
      onceTableConfig: {
        tableActionOptions: {
          enableMultiSelectActions: true,
          enableRowButtonActions: true,
          enableRowContextMenuActions: true,
          tableActions: [
            {
              type: 'HEADER',
              buttonType: 'dark',
              label: 'Add Donation',
              icon: 'fa fa-plus',
              onClick: () => {
                this.setState({ showAddDonation: true });
              },
            },
            {
              type: 'HEADER',
              buttonType: 'dark',
              onClick: () =>
                this.setState({
                  activeDonation: null,
                  showReconcilePaymentModal: true,
                }),
              label: 'Reconcile Payment',
            },
            {
              type: 'HEADER_POPUP_MENU',
              popupMenuClass: 'export-popup-items-container--reconcile-payment',
              menuItems: [
                {
                  id: 'Export_Donations',
                  label: 'Export Donations',
                  icon: 'far fa-file-download',
                  isSelected: false,
                  onClick: () => this.exportDonationCSV(),
                },
                {
                  icon: 'far fa-file-download',
                  onClick: () => this.exportPledgesCSV(),
                  label: 'Export Pledges',
                  id: 'Export_Pledges',
                },
                {
                  icon: 'far fa-file-download',
                  onClick: () =>
                    this.setState({
                      exportTaxReceiptsAction: TaxReceiptExportType.IssuedTaxReceipts,
                    }),
                  label: 'Export Issued Tax Receipts',
                  id: 'Export_Issued_Tax_Receipts',
                },
                {
                  icon: 'far fa-file-download',
                  onClick: () =>
                    this.setState({
                      exportTaxReceiptsAction: TaxReceiptExportType.SkippedTaxReceipts,
                    }),
                  label: 'Export Skipped Tax Receipts',
                  id: 'Export_Skipped_Tax_Receipts',
                },
              ],
              renderPopupMenuView: () => this.renderPopupMenuView(),
            },
            ...this.getMenuItems(),
          ],
          rowDetailDrawerOptions: (donation) => ({
            title: '',
            renderContent: () => <DonationListExpandedCell donation={donation} />,
          }),
        },
        rowSelectionOptions: {
          rowId: (row) => typeHelpers.tryGetAssertNotNullOrUndefinedValue(row.id),
          enableRowSelectionMode:
            this.props.owner.ownerType === Constants.object_type.group ? 'multi-select' : undefined,
          isRowSelectable: (row) => !!row.donation_match,
        },
        columns: [
          {
            id: 'user.display_name',
            sortable: true,
            notranslate: 'yes',
            Header: 'Donor',
            accessor: (donation) => donation.user.display_name,
          },
          {
            id: 'amounts.amount',
            sortable: true,
            Header: 'Amount',
            notranslate: 'yes',
            Cell: (donation) => {
              const exchangeRate = donation.reporting_exchange_rate ?? 1;
              const currency = donation.reporting_currency ?? defaultCurrency;
              const amount =
                donation.amounts.amount * (donation.amounts?.amount_multiplier ?? 1) * exchangeRate;
              return (
                <var data-var="donation_amounts_amount_2">
                  {formatCurrency(amount, currency, this.props.locale)}
                </var>
              );
            },
            accessor: (donation) => donation.amounts.amount,
          },
          {
            id: 'created_at',
            sortable: true,
            notranslate: 'yes',
            Header: 'Donation Date',
            Cell: (donation) => {
              return (
                <span notranslate="yes">
                  {localizeHelpers.formatDate(
                    donation.created_at ?? new Date(),
                    LocaleDateFormats.LL,
                    this.props.locale,
                  )}
                </span>
              );
            },
            accessor: (donation) => donation.created_at?.toString(),
          },
          {
            id: 'tax_receipt_status.code',
            sortable: true,
            Header: 'Tax Receipt Status',
            predefinedColumnType: {
              type: 'STATUS',
              columnObject: () => ({
                [Constants.tax_receipt_status.not_created]: {
                  label: 'Not Created',
                  color: 'GREY',
                },
                [Constants.tax_receipt_status.sent]: {
                  label: 'Sent',
                  color: 'GREEN',
                },
                [Constants.tax_receipt_status.pending]: {
                  label: 'Pending',
                  color: 'YELLOW',
                },
              }),
            },
            accessor: (donation) => donation.tax_receipt_status?.code,
          },
          {
            id: 'amounts.payment_status.code',
            sortable: true,
            Header: 'Payment Status',
            predefinedColumnType: {
              type: 'STATUS',
              columnObject: () => ({
                pending: {
                  label: 'Pending',
                  color: 'YELLOW',
                },
                refunded: {
                  label: 'Refunded',
                  color: 'RED',
                },
                unpaid: {
                  label: 'Unpaid',
                  color: 'GREY',
                },
                paid: {
                  label: 'Paid',
                  color: 'GREEN',
                },
              }),
            },
            accessor: (donation) => donation?.amounts?.payment_status?.code,
          },
          this.props.owner.ownerType === Constants.object_type.group
            ? {
                id: 'donation_match.match_status.code',
                sortable: true,
                Header: 'Matched Status',
                predefinedColumnType: {
                  type: 'STATUS',
                  columnObject: () => ({
                    declined: {
                      label: 'Declined',
                      color: 'RED',
                    },
                    not_applicable: {
                      label: 'Not Applicable',
                      color: 'GREY',
                    },
                    matched: {
                      label: 'Matched',
                      color: 'GREEN',
                    },
                    pending: {
                      label: 'Pending',
                      color: 'YELLOW',
                    },
                  }),
                },
                accessor: (donation) =>
                  donation?.donation_match?.match_status.code || 'not_applicable',
              }
            : {
                id: 'amounts.payment_method',
                sortable: true,
                Header: 'Type',
                accessor: (donation) => donation?.amounts.payment_method,
              },
        ],
        pagination: {
          pageSizeOptions: [10],
          queryAction: async (params) => await this.getData(params),
        },
        emptyStateConfig: {
          title: 'No data',
          description: "We couldn't find any donations",
        },
        filterOptions: {
          enableColumnSorting: true,
          enableTableSearch: true,
          enableFilterDrawer: true,
          filterDrawerOptions: [
            {
              type: 'textField',
              fieldId: 'user.display_name',
              options: { label: 'Donor' },
            },
            {
              type: 'textField',
              fieldId: 'amounts.amount',
              options: { label: 'Amount' },
            },
            {
              type: 'multiSelectField',
              fieldId: 'tax_receipt_status.code',
              options: {
                label: 'Tax Receipt Status',
                options: [
                  { value: Constants.tax_receipt_status.not_created, label: 'Not created' },
                  { value: Constants.tax_receipt_status.sent, label: 'Sent' },
                  { value: Constants.tax_receipt_status.pending, label: 'Pending' },
                ],
              },
            },
            {
              type: 'multiSelectField',
              fieldId: 'amounts.payment_status.code',
              options: {
                label: 'Payment status',
                options: [
                  { value: 'pending', label: 'Pending' },
                  { value: 'refunded', label: 'Refunded' },
                  { value: 'unpaid', label: 'Unpaid' },
                  { value: 'paid', label: 'Paid' },
                ],
              },
            },
            {
              type: 'multiSelectField',
              fieldId: 'donation_match.match_status.code',
              options: {
                label: 'Matched Status',
                options: [
                  { value: 'declined', label: 'Declined' },
                  { value: 'matched', label: 'Matched' },
                  { value: 'pending', label: 'Pending' },
                ],
              },
            },
          ],
        },
      },
      onceRefreshTableIncrementor: 0,
      showEditModal: false,
      showGenerateAndEditModal: false,
      last4Digits: '',
      cardBrand: '',
      donations: [],
      donationType: uiConstants.donationType.once,
      showRefundModal: false,
      refund_amount: 0,
      order: null,
      showThankYouModal: false,
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      gender: 'male',
      address: '',
      apartment: '',
      city: '',
      selectedProvince: '',
      country: '',
      postal: '',
      campaignFunds:
        this.props.owner.ownerType === Constants.object_type.group
          ? this.getFundOptions(
              (this.props.groupState.currentCampaign &&
                this.props.groupState.currentCampaign.funds) ||
                [],
            )
          : this.getFundOptions(
              (this.props.eventState.currentCampaign &&
                this.props.eventState.currentCampaign.funds) ||
                [],
            ),
      donationAmount: 25,
      comments: '',
      otherAmount: '0',
      anonymous: false,
      sendReceipt: false,
      dedicate: false,
      selectedDedicate: '',
      hFirst: '',
      hLast: '',
      rFirst: '',
      rLast: '',
      rEmail: '',
      rMessage: '',
      dPrefix: 'memory',
      formRef: React.createRef(),
      donateTo: '',
      selectedTeam: '',
      selectedIndividual: '',
      teams: [],
      isFundraiser: false,
      showSelectPaymentModal: false,
      selectedPaymentMethod: uiConstants.adminPaymentMethods[0].value,
      searchTimeout: null,
      sort: [],
      activeDonation: null,
      selectedItems: [],
      bulkActionLoading: false,
      showBulkErrors: false,
      bulkErrorMessages: [],
      fullDonationRefund: false,
      changeDonationDestination: null,
      currentPage: 0,
      hasMore: false,
      recurringPaymentToCancel: false,
      paymentIdToCancel: '',
      donationSubscriptions: [],
      donationsLoading: false,
    };

    this.toggleRefundModal = this.toggleRefundModal.bind(this);
    this.sendConfirmationEmail = this.sendConfirmationEmail.bind(this);
    this.sendConfirmationEmailBulk = this.sendConfirmationEmailBulk.bind(this);
    this.bulkRegenerateTaxReceipts = this.bulkRegenerateTaxReceipts.bind(this);
    this.bulkSendTaxReceipt = this.bulkSendTaxReceipt.bind(this);
    this.getPaymentStatus = this.getPaymentStatus.bind(this);
    this.markAsPaid = this.markAsPaid.bind(this);
    this.setSelected = this.setSelected.bind(this);
  }

  exportPledgesCSV() {
    if (this.props.owner.ownerType === uiConstants.ownerType.group) {
      this.props.getPledgesExport(
        this.props.groupState.group.id,
        this.props.groupState.group.handle,
      );
    }
  }

  exportDonationCSV() {
    if (this.props.owner.ownerType === uiConstants.ownerType.group) {
      this.props.getDonationsExport(
        this.props.groupState.group.id,
        this.props.groupState.group.handle,
      );
    } else if (this.props.owner.ownerType === uiConstants.ownerType.event) {
      this.props.getDonationsExportEvent(
        this.props.eventState.event.id,
        this.props.eventState.event.handle,
      );
    }
  }

  getData(params: URLSearchParams) {
    return this.getDonations({
      eventId:
        this.props.owner.ownerType === Constants.object_type.event
          ? this.props.owner.ownerId
          : undefined,
      groupId:
        this.props.owner.ownerType === Constants.object_type.group
          ? this.props.owner.ownerId
          : undefined,
      subscription: this.state.donationType === uiConstants.donationType.recurring ?? true,
      query: params,
    });
  }

  renderPopupMenuView() {
    return (
      <div className="export-popup-menu-placeholder">
        <Button
          icon="far fa-file-download"
          type="button"
          buttonType="outline-dark"
          text="Export"
        />
      </div>
    );
  }

  async getDonations(params: {
    groupId?: string;
    eventId?: string;
    subscription?: boolean;
    query?: URLSearchParams;
  }) {
    let donationsData: ITransactionSummary[] = [];

    if (this.state.donationType === uiConstants.donationType.once) {
      params.query?.append('flags[recurring]', 'false');
    }

    try {
      donationsData = await donationRequestActions.getDonations({
        ...params,
        query: params.query,
      });
    } catch (error) {
      const errObj = errorHelpers.getErrorObject(error);
      const toast = toastError(errObj.translatedMessage, 'Get donations');
      this.props.createToast(toast);
    }

    if (params?.eventId) {
      // Stan Trotsko, I commented it out because for now we do not allow events subscription.
      // When subscriptions for events will be implemented we need to create a method something like -> getEventSubscription()
      //this.props.getGroupSubscriptions(this.props.eventState.event.group_id);
      this.props.getEventTeams(this.props.owner.ownerId);
      this.props.getEventIndividuals(this.props.owner.ownerId);
    }

    return donationsData;
  }

  getFundOptions(funds: ICampaignFund[]) {
    return funds.map((fund: ICampaignFund) => {
      return { label: fund.name, value: fund.name };
    });
  }

  componentDidUpdate(prevProps: IProps, prevState: IState) {
    if (this.props.owner.ownerType === Constants.object_type.group) {
      if (this.props.groupState.groupDonations !== prevProps.groupState.groupDonations) {
        this.setState({
          donations: this.props.groupState.groupDonations,
        });
        this.refreshTables();
      }
    }

    if (this.props.owner.ownerType === Constants.object_type.event) {
      if (this.props.eventState.eventDonations !== prevProps.eventState.eventDonations) {
        this.setState({
          donations: this.props.eventState.eventDonations,
        });
        this.refreshTables();
      }

      if (prevProps.eventState.teams !== this.props.eventState.teams) {
        let teams = this.props.eventState.teams.map((team) => {
          return { label: team.name, value: team.handle };
        });

        teams.unshift({ label: 'None', value: '' });

        this.setState(
          {
            teams: teams,
          },
          () => {
            if (this.state.teams[0] && this.state.selectedTeam === '') {
              this.setState({
                selectedTeam: teams[0].value,
              });
            }
          },
        );
      }
    }

    if (this.props.refreshHandle !== prevProps.refreshHandle) {
      this.refreshTables();
    }
  }

  initRefund(donation: ITransactionSummary) {
    this.setOrder(donation);
  }

  async refundDonation(params: {
    groupId?: string;
    eventId?: string;
    donationId: string;
    params: {
      amount: number | string;
      group_title: string;
      full_refund: boolean;
    };
  }): Promise<void> {
    try {
      await donationRequestActions.refundDonation(params);

      const toast = toastSuccess(localizeHelpers.translate('Donation Refunded.'), 'Donation');
      this.props.createToast(toast);

      await this.getDonations({
        groupId:
          this.props.owner.ownerType === Constants.object_type.group
            ? this.props.owner.ownerId
            : undefined,
        eventId:
          this.props.owner.ownerType === Constants.object_type.event
            ? this.props.owner.ownerId
            : undefined,
      });
    } catch (error) {
      const errorObj = errorHelpers.getErrorObject(error);
      let toast = toastError(errorObj.translatedMessage, 'Refund');
      this.props.createToast(toast);
    }
  }

  async refund() {
    let groupTitle = '';
    let groupId = '';
    let eventId = '';

    if (this.props.owner.ownerType === Constants.object_type.group) {
      groupTitle = this.props.owner.ownerHandle;
      groupId = this.props.owner.ownerId;
    } else if (this.props.owner.ownerType === Constants.object_type.event) {
      groupTitle = this.props.eventState.event.group?.title || '';
      groupId = this.props.eventState.event.group?.id!;
      eventId = this.props.eventState.event.id;
    }

    const exchangeRate = this.state.order?.reporting_exchange_rate ?? 1;

    const _payload = {
      amount: Number(this.state.refund_amount) / exchangeRate, // previous this.state.fullDonationRefund ? undefined : Number(this.state.refund_amount) / exchangeRate,
      group_title: groupTitle,
      full_refund: this.state.fullDonationRefund,
    };

    if (typeof _payload.amount === 'string') {
      _payload.amount = parseFloat(_payload.amount);
    }

    switch (this.props.owner.ownerType) {
      case 'group':
        if (this.state.order?.id) {
          await this.refundDonation({
            groupId,
            donationId: this.state.order.id,
            params: _payload,
          });
        }
        break;
      case 'event':
        if (this.state.order?.id) {
          await this.refundDonation({
            eventId,
            donationId: this.state.order.id,
            params: _payload,
          });
        }
        break;
      default:
        throw new Error(`Cannot refund donation for parent type ${this.props.owner.ownerType}`);
    }

    this.toggleRefundModal(false);
    this.setState({
      fullDonationRefund: false,
      refund_amount: 0,
      onceRefreshTableIncrementor: this.state.onceRefreshTableIncrementor + 1,
    });
  }

  toggleRefundModal(value: boolean) {
    this.setState({
      showRefundModal: value,
      fullDonationRefund: value && this.state.fullDonationRefund,
    });
  }

  setOrder(_order: ITransactionSummary) {
    this.setState(
      {
        order: _order,
      },
      () => {
        if (this.state.order) {
          this.setState({
            refund_amount: (
              (this.state.order.amounts.amount + this.state.order.amounts.payment_platform_fee) *
              (this.state.order.amounts?.amount_multiplier || 1) *
              (this.state.order?.reporting_exchange_rate || 1)
            )
              .toFixed(2)
              .toString(),
          });
        }

        this.toggleRefundModal(true);
      },
    );
  }

  async sendConfirmationEmailBulk() {
    let groupId =
      this.props.owner.ownerType === Constants.object_type.group
        ? this.props.owner.ownerId
        : this.props.eventState.event.group_id;

    this.setState({
      bulkActionLoading: true,
    });

    let route = swapRouteParams(routes.SEND_CONFIRMATION_EMAIL_BULK, { groupId });
    let _payload = {
      selected: this.state.selectedItems,
    };

    try {
      let response = await axios.post(route, _payload);
      if (response.data.success_count) {
        const toast = toastSuccess(
          localizeHelpers.translate('Successfully sent {{success_count}} confirmation emails.', {
            success_count: response.data.success_count,
          }),
          'Transaction Record',
        );
        this.props.createToast(toast);
      }
      if (response.data.failed_count) {
        let messages: string[] = [];
        response.data.errors.forEach((error: IBulkActionError) => {
          const errObj = errorHelpers.getErrorObject(error);
          messages.push(errObj.translatedMessage);
        });

        this.setState({
          bulkErrorMessages: messages,
          showBulkErrors: true,
        });
      }

      this.setState({
        bulkActionLoading: false,
      });
    } catch (error) {
      const errObj = errorHelpers.getErrorObject(error);
      const toast = toastError(errObj.translatedMessage, 'Send Confirmation Email');
      this.props.createToast(toast);
    }
  }

  async bulkRegenerateTaxReceipts() {
    let route = '';
    let _payload = {
      selected: this.state.selectedItems,
    };

    if (this.props.owner.ownerType === Constants.object_type.group) {
      route = swapRouteParams(routes.REGENERATE_TAX_RECEIPT_BULK, {
        groupId: this.props.owner.ownerId,
      });
    } else if (this.props.owner.ownerType === Constants.object_type.event) {
      route = swapRouteParams(routes.REGENERATE_EVENT_TAX_RECEIPT_BULK, {
        eventId: this.props.owner.ownerId,
      });
    }

    try {
      if (route && route !== '') {
        let response = await axios.post(route, _payload);
        if (response.data.success_count) {
          const toast = toastSuccess(
            localizeHelpers.translate(
              'Successfully (Re)generated {{success_count}} tax receipts.',
              {
                success_count: response.data.success_count,
              },
            ),
            'Transaction Record',
          );
          this.props.createToast(toast);
        }
        if (response.data.failed_count) {
          let messages: string[] = [];
          response.data.errors.forEach((error: IBulkActionError) => {
            const errObj = errorHelpers.getErrorObject(error);
            messages.push(errObj.translatedMessage);
          });
          this.setState({
            bulkErrorMessages: messages,
            showBulkErrors: true,
          });
        }
      }
    } catch (error) {
      const errObj = errorHelpers.getErrorObject(error);
      const toast = toastError(errObj.translatedMessage, 'Generate Tax Receipt');
      this.props.createToast(toast);
    }
  }

  async bulkSendTaxReceipt() {
    let route = swapRouteParams(routes.SEND_TAX_RECEIPT_BULK, {
      groupId: this.props.groupState.group.id,
    });
    let _payload = {
      selected: this.state.selectedItems,
    };

    try {
      let response = await axios.post(route, _payload);
      if (response.data.success_count) {
        const toast = toastSuccess(
          localizeHelpers.translate('Successfully sent {{success_count}} tax receipts.', {
            success_count: response.data.success_count,
          }),
          'Transaction Record',
        );
        this.props.createToast(toast);
      }
      if (response.data.failed_count) {
        let messages: string[] = [];
        response.data.errors.forEach((error: IBulkActionError) => {
          const errObj = errorHelpers.getErrorObject(error);
          messages.push(errObj.translatedMessage);
        });
        this.setState({
          bulkErrorMessages: messages,
          showBulkErrors: true,
        });
      }
    } catch (error) {
      const errObj = errorHelpers.getErrorObject(error);
      const toast = toastError(errObj.translatedMessage, 'Send Tax Receipt');
      this.props.createToast(toast);
    }
  }

  sendConfirmationEmail(id: string) {
    if (this.props.owner.ownerType === Constants.object_type.group) {
      this.props.sendConfirmationEmail(this.props.groupState.group.id, id);
    } else if (
      this.props.owner.ownerType === Constants.object_type.event &&
      this.props.eventState.event.group_id
    ) {
      this.props.sendConfirmationEmail(this.props.eventState.event.group_id, id);
    }

    const toast = toastSuccess(
      localizeHelpers.translate('Confirmation email successfully sent.'),
      'Transaction Record',
    );
    this.props.createToast(toast);
  }

  setDonationType(donationType: string) {
    this.setState({ donationType });
  }

  isTaxReceiptSupported() {
    return Constants.supported_tax_receipt_countries.includes(
      this.props.owner.account?.country || '',
    );
  }

  hasTaxReceipts() {
    return (
      this.props.groupState.groupLocations.length > 0 &&
      this.props.groupState.group.charity_id &&
      this.props.groupState.group.charity_id.length > 0 &&
      this.props.groupState.groupSignature
    );
  }

  // TODO: Remove `markAsPaid` and all donation invoice related state/functions.
  /**
     * - Similarly `updateTransactionPayment()` can be moved
        - Same with `donationRequestActions.updateTransactionPayment()`
        - Same with the "Mark as Paid" modal at https://github.com/gigitmarket/gigit-platform/blob/e4b4447211def00c1e0e35c0b796b5946a0c004e/packages/ui/gigit-ui-web/src/components/DonationList/DonationList.tsx#L1481 and any start related to this.
     */
  markAsPaid(donation: ITransactionSummary) {
    this.setState({
      order: donation,
      showSelectPaymentModal: true,
      onceRefreshTableIncrementor: this.state.onceRefreshTableIncrementor + 1,
    });
  }

  getPaymentStatus(donation: any): string {
    if (donation.amounts.is_refund) return 'refunded';
    if (
      ['card', 'cash', 'cheque', 'in-kind', 'other', 'e-transfer'].includes(
        donation.amounts.payment_method,
      )
    )
      return 'paid';
    if (donation.amounts.payment_status) return donation.amounts.payment_status.code;

    return 'unpaid';
  }

  downloadReceipt(donation: ITransactionSummary) {
    switch (this.props.owner.ownerType) {
      case Constants.object_type.group:
        this.props.downloadGroupDonationReceipt(this.props.owner.ownerId, donation);
        break;
      case Constants.object_type.event:
        this.props.downloadEventDonationReceipt(this.props.owner.ownerId, donation);
        break;
      default:
        throw new Error(
          `Cannot download donation receipt for parent type ${this.props.owner.ownerType}`,
        );
    }
  }

  search() {
    if (this.state.donationType === uiConstants.donationType.recurring) {
      this.setState({
        recurringRefreshTableIncrementor: this.state.recurringRefreshTableIncrementor + 1,
      });
    } else if (this.state.donationType === uiConstants.donationType.once) {
      this.setState({
        onceRefreshTableIncrementor: this.state.onceRefreshTableIncrementor + 1,
      });
    }
  }

  setSelected(ids: string[]) {
    this.setState({
      selectedItems: ids,
    });
  }

  calculateRefundableAmount() {
    const { amounts } = this.state.order!;
    return (
      (this.state.fullDonationRefund
        ? amounts.total
        : amounts.amount + amounts.payment_platform_fee) * (amounts.amount_multiplier ?? 1)
    );
  }

  async openGenerateModal(donation: ITransactionSummary): Promise<void> {
    this.setState({
      activeDonation: donation,
      showGenerateAndEditModal: true,
    });
  }

  getMenuItems() {
    let items: ITableAction<ITransactionSummary>[] = [];

    const hideIf = (donation: ITransactionSummary) => !!donation.amounts.is_refund;

    const generateTaxReceipt: ITableAction<ITransactionSummary> = {
      type: 'ROW_CONTEXT_MENU',
      icon: 'fas fa-receipt',
      hideIf: (donation) =>
        hideIf(donation) ||
        !(donation?.tax_receipt_status?.code && !donation.recurring) ||
        donation.tax_receipt_status.code !== Constants.tax_receipt_status.not_created ||
        !this.isTaxReceiptSupported(),
      onClick: async (e: MouseEvent<HTMLElement>, donation) => {
        e?.stopPropagation();
        await this.openGenerateModal(donation);
      },
      label: 'Generate Tax Receipt',
    };
    const refundItemComponent: ITableAction<ITransactionSummary> = {
      type: 'ROW_CONTEXT_MENU',
      icon: 'far fa-dollar-sign',
      hideIf: (donation) =>
        hideIf(donation) ||
        !(donation?.tax_receipt_status?.code && !donation.recurring) ||
        donation.transaction_sub_type === Constants.transaction_sub_type.donation_matching_payout ||
        donation.tax_receipt_status.code !== Constants.tax_receipt_status.not_created ||
        (donation.tax_receipt_status.code === Constants.tax_receipt_status.not_created &&
          this.getPaymentStatus(donation) === Constants.payment_status.unpaid) ||
        (donation.tax_receipt_status.code === Constants.tax_receipt_status.sent &&
          this.getPaymentStatus(donation) === Constants.payment_status.unpaid),
      onClick: (e: MouseEvent<HTMLElement>, donation) => {
        e?.stopPropagation();
        this.initRefund(donation);
      },
      label: 'Refund',
    };
    const viewTaxReceipt: ITableAction<ITransactionSummary> = {
      type: 'ROW_CONTEXT_MENU',
      icon: 'fa fa-file-pdf-o',
      hideIf: (donation) =>
        hideIf(donation) ||
        !(donation?.tax_receipt_status?.code && !donation.recurring) ||
        !(donation.tax_receipt_status?.code === Constants.tax_receipt_status.sent) ||
        (donation.tax_receipt_status.code === Constants.tax_receipt_status.pending &&
          this.getPaymentStatus(donation) === Constants.payment_status.unpaid) ||
        (donation.tax_receipt_status.code === Constants.tax_receipt_status.sent &&
          this.getPaymentStatus(donation) === Constants.payment_status.unpaid),
      onClick: (e: MouseEvent<HTMLElement>, donation) => {
        e?.stopPropagation();
        this.downloadReceipt(donation);
      },
      label: 'View Tax Receipt',
    };
    const changeDonationDestination: ITableAction<ITransactionSummary> = {
      type: 'ROW_CONTEXT_MENU',
      icon: 'fa fa-exchange',
      hideIf: (donation) =>
        hideIf(donation) ||
        !(donation?.tax_receipt_status?.code && !donation.recurring) ||
        ![Constants.tax_receipt_status.not_created, Constants.tax_receipt_status.pending].includes(
          donation.tax_receipt_status.code,
        ),
      onClick: (e: MouseEvent<HTMLElement>, donation) =>
        this.setState({ changeDonationDestination: donation }),
      label: 'Change Designation',
    };
    const reGenerateTaxReceipt: ITableAction<ITransactionSummary> = {
      type: 'ROW_CONTEXT_MENU',
      icon: 'fa fa-file',
      hideIf: (donation) =>
        hideIf(donation) ||
        !(donation?.tax_receipt_status?.code && !donation.recurring) ||
        (donation.tax_receipt_status.code === Constants.tax_receipt_status.sent &&
          this.getPaymentStatus(donation) === Constants.payment_status.unpaid),
      onClick: async (e: MouseEvent<HTMLElement>, donation) => {
        e?.stopPropagation();
        await this.openGenerateModal(donation);
      },
      label: 'Re-Generate tax receipt',
    };
    const sendConfirmationEmail: ITableAction<ITransactionSummary> = {
      type: 'ROW_CONTEXT_MENU',
      icon: 'fa fa-envelope',
      hideIf: (donation) =>
        hideIf(donation) ||
        !(donation?.tax_receipt_status?.code && !donation.recurring) ||
        !(donation.tax_receipt_status?.code === Constants.tax_receipt_status.sent) ||
        (donation.tax_receipt_status.code === Constants.tax_receipt_status.pending &&
          this.getPaymentStatus(donation) === Constants.payment_status.unpaid) ||
        (donation.tax_receipt_status.code === Constants.tax_receipt_status.sent &&
          this.getPaymentStatus(donation) === Constants.payment_status.unpaid),
      onClick: (e: MouseEvent<HTMLElement>, donation) => {
        e?.stopPropagation();
        this.sendConfirmationEmail(donation.id ?? '');
      },
      label: 'Send Confirmation Email',
    };
    const sendTaxReceipt: ITableAction<ITransactionSummary> = {
      type: 'ROW_CONTEXT_MENU',
      icon: 'fa fa-envelope',
      hideIf: (donation) => {
        return (
          hideIf(donation) ||
          !(donation?.tax_receipt_status?.code && !donation.recurring) ||
          donation?.tax_receipt_status?.code !== Constants.tax_receipt_status.pending ||
          !(this.getPaymentStatus(donation) !== Constants.payment_status.unpaid) ||
          !this.hasTaxReceipts()
        );
      },
      onClick: async (e: MouseEvent<HTMLElement>, donation) => {
        this.setState(
          {
            activeDonation: donation,
          },
          async () => {
            await this.sendTaxReceipt(
              this.props.owner.ownerType === Constants.object_type.group
                ? { groupId: this.props.owner.ownerId }
                : { eventId: this.props.owner.ownerId },
            );
          },
        );
      },
      label: 'Send Tax Receipt',
    };
    const editTaxReceipt: ITableAction<ITransactionSummary> = {
      type: 'ROW_CONTEXT_MENU',
      icon: 'fa fa-edit',
      hideIf: (donation) =>
        hideIf(donation) ||
        !(donation?.tax_receipt_status?.code && !donation.recurring) ||
        !(donation.tax_receipt_status?.code === Constants.tax_receipt_status.sent) ||
        (donation.tax_receipt_status.code === Constants.tax_receipt_status.pending &&
          this.getPaymentStatus(donation) === Constants.payment_status.unpaid),
      onClick: (e: MouseEvent<HTMLElement>, donation) =>
        this.setState({
          activeDonation: donation,
          showGenerateAndEditModal: true,
        }),
      label: 'Edit Tax Receipt',
    };
    const editThankYouMessage: ITableAction<ITransactionSummary> = {
      type: 'ROW_CONTEXT_MENU',
      icon: 'fa fa-envelope',
      hideIf: (donation) =>
        hideIf(donation) ||
        !(donation?.tax_receipt_status?.code && !donation.recurring) ||
        !(donation.tax_receipt_status?.code === Constants.tax_receipt_status.sent) ||
        (donation.tax_receipt_status.code === Constants.tax_receipt_status.pending &&
          this.getPaymentStatus(donation) === Constants.payment_status.unpaid) ||
        (donation.tax_receipt_status.code === Constants.tax_receipt_status.sent &&
          this.getPaymentStatus(donation) === Constants.payment_status.unpaid),
      onClick: (e: MouseEvent<HTMLElement>, donation) => {
        this.setState({
          activeDonation: donation,
          showThankYouModal: true,
        });
      },
      label: 'Edit Thank You Message',
    };
    const reconcilePayment: ITableAction<ITransactionSummary> = {
      type: 'ROW_CONTEXT_MENU',
      icon: 'fa fa-check',
      hideIf: (donation) =>
        hideIf(donation) ||
        !donation?.donation_match ||
        !(
          donation.donation_match?.match_status.code ===
          Constants.donation_matching_transaction_status.matched
        ),
      onClick: (e: MouseEvent<HTMLElement>, donation) => {
        this.setState({
          activeDonation: donation,
          showReconcilePaymentModal: true,
        });
      },
      label: 'Reconcile Payment',
    };
    const refundedTransaction: ITableAction<ITransactionSummary> = {
      type: 'ROW_CONTEXT_MENU',
      icon: '',
      hideIf: (donation) =>
        hideIf(donation) ||
        (donation?.recurring !== undefined &&
          this.state.donationType === 'recurring' &&
          donation?.account?.subscription?.status?.code === 'active') ||
        !(donation.amounts.refunds?.length && donation.amounts.refunds?.length > 0) ||
        donation.transaction_sub_type === Constants.transaction_sub_type.donation_matching_payout,
      onClick: (e: MouseEvent<HTMLElement>, donation) => {
        this.toggleRefundModal(true);
      },
      label: 'Refunded transaction',
    };

    items.push(
      generateTaxReceipt,
      reconcilePayment,
      refundedTransaction,
      viewTaxReceipt,
      refundItemComponent,
      changeDonationDestination,
      editTaxReceipt,
      reGenerateTaxReceipt,
      sendTaxReceipt,
      editThankYouMessage,
      sendConfirmationEmail,
    );

    return items;
  }

  async sendTaxReceipt(params: { groupId?: string; eventId?: string }) {
    try {
      if (this.state.activeDonation?.id) {
        await donationRequestActions.sendTaxReceipt({
          groupId: params?.groupId,
          eventId: params?.eventId,
          donationId: this.state.activeDonation.id,
        });
        const toast = toastSuccess(
          localizeHelpers.translate('Donation receipt successfully sent.'),
          'Transaction Record',
        );
        this.props.createToast(toast);

        await this.getDonations({
          groupId:
            this.props.owner.ownerType === Constants.object_type.group
              ? this.props.owner.ownerId
              : undefined,
          eventId:
            this.props.owner.ownerType === Constants.object_type.event
              ? this.props.owner.ownerId
              : undefined,
        });
      }
    } catch (error) {
      const errorObj = errorHelpers.getErrorObject(error);
      let toast = toastError(errorObj.translatedMessage, 'Transaction Record');
      this.props.createToast(toast);
    }
  }

  hideGenerateModal(): void {
    this.setState({
      showGenerateAndEditModal: false,
      activeDonation: null,
    });
    this.refreshTables();
  }

  async cancelRecurringPayment() {
    if (this.state.recurringPaymentToCancel && this.state.paymentIdToCancel !== '') {
      try {
        this.props.cancelGroupRecurringDonation(
          this.props.groupState.group.id,
          this.state.paymentIdToCancel,
        );
      } catch (error) {
        const errorObj = errorHelpers.getErrorObject(error);
        let toast = toastError(errorObj.translatedMessage, 'Cancel Recurring Payment');
        this.props.createToast(toast);
      }
    }
  }

  render() {
    let bulkActions = [];
    if (this.hasTaxReceipts()) {
      bulkActions.push(
        {
          icon: 'fa fa-file-pdf-o',
          onClick: async () => {
            await this.bulkRegenerateTaxReceipts();
          },
          label: 'Generate Tax Receipt',
        },
        {
          icon: 'fa fa-arrow-circle-right',
          onClick: async () => {
            await this.bulkSendTaxReceipt();
          },
          label: 'Send Tax Receipts',
        },
      );
    }

    bulkActions.push(
      {
        icon: 'fa fa-pen',
        onClick: () => {
          this.setState({ showThankYouModal: true });
        },
        label: 'Edit Thank You Message',
      },
      {
        icon: 'fa fa-envelope',
        onClick: async () => {
          await this.sendConfirmationEmailBulk();
        },
        label: 'Send Confirmation Email',
      },
    );

    return (
      <div className="Donation-list">
        {this.props.owner.ownerType === Constants.object_type.group && (
          <div className="filters">
            <div
              className={
                this.state.donationType === uiConstants.donationType.once
                  ? 'filter-btn active no-select'
                  : 'filter-btn no-select'
              }
              onClick={() => this.setDonationType(uiConstants.donationType.once)}
            >
              One-Time
            </div>
            <div
              className={
                this.state.donationType === uiConstants.donationType.recurring
                  ? 'filter-btn active no-select'
                  : 'filter-btn no-select'
              }
              onClick={() => this.setDonationType(uiConstants.donationType.recurring)}
            >
              Recurring
            </div>
          </div>
        )}
        {this.state.donationType === uiConstants.donationType.once && (
          <div className="donation-list-items">
            <div className="list-inner">
              <div className="list-rows">
                {this.state.donationType === uiConstants.donationType.once &&
                  this.state.donations && (
                    <Table
                      {...this.state.onceTableConfig}
                      refreshTableIncrementor={this.state.onceRefreshTableIncrementor}
                    />
                  )}
              </div>
            </div>
          </div>
        )}
        {this.state.donationType === uiConstants.donationType.recurring && (
          <div className="donation-list-items">
            <div className="list-inner">
              <div className="list-rows">
                {this.props.owner.ownerType === Constants.object_type.group &&
                  this.state.donationType === uiConstants.donationType.recurring && (
                    <Table
                      {...this.state.recurringTableConfig}
                      refreshTableIncrementor={this.state.recurringRefreshTableIncrementor}
                    />
                  )}
              </div>
            </div>
          </div>
        )}

        <BulkErrors
          showBulkErrors={this.state.showBulkErrors}
          hideModal={() => this.setState({ showBulkErrors: false, bulkErrorMessages: [] })}
          bulkErrorMessages={this.state.bulkErrorMessages}
        />

        {this.state.activeDonation && (
          <EditThankYouMessage
            donation={this.state.activeDonation}
            showThankYouModal={this.state.showThankYouModal}
            hideModal={() => this.setState({ showThankYouModal: false, activeDonation: null })}
            createToast={this.props.createToast}
            owner={this.props.owner}
            eventState={this.props.eventState}
            groupState={this.props.groupState}
            getDonations={async (params: { groupId?: string; eventId?: string }) => {
              await this.getDonations(params);
            }}
            setBulkActionLoading={(v) => {
              this.setState({ bulkActionLoading: v });
            }}
            selectedItems={this.state.selectedItems}
            setBulkMsgAndError={(messages, showError) => {
              this.setState({ bulkErrorMessages: messages, showBulkErrors: showError });
            }}
            setActionAndThankYouModal={(bulkActionLoading: boolean, showThankYouModal: boolean) => {
              this.setState({ bulkActionLoading, showThankYouModal });
            }}
            resetThankYouModalAndCurrentTransaction={() => {
              this.setState(
                {
                  showThankYouModal: false,
                  activeDonation: null,
                },
                () => {
                  const toast = toastSuccess(
                    localizeHelpers.translate('Thank you message successfully updated.'),
                    'Transaction Record',
                  );
                  this.props.createToast(toast);
                },
              );
            }}
          />
        )}

        <Modal
          show={
            this.state.showReconcilePaymentModal &&
            this.props.owner.ownerType === Constants.object_type.group
          }
          onClose={() => this.setState({ showReconcilePaymentModal: false })}
          class="ReconcilePayment-Modal"
          closeIcon="fas fa-times"
        >
          <ReconcilePayment
            onClose={() => this.setState({ showReconcilePaymentModal: false })}
            groupId={this.props.owner.ownerId}
          />
        </Modal>

        {this.state.order?.id && this.renderRefundModal()}

        <SelectPayment
          showSelectPaymentModal={this.state.showSelectPaymentModal}
          hideModal={() => this.setState({ showSelectPaymentModal: false })}
          owner={this.props.owner}
          createToast={this.props.createToast}
          order={this.state.order}
          getDonations={async (params: { groupId?: string; eventId?: string }) => {
            await this.getDonations(params);
          }}
        />

        <GenerateAndEditTaxReceipt
          showGenerateAndEditModal={this.state.showGenerateAndEditModal}
          activeDonation={this.state.activeDonation}
          hideModal={() => this.hideGenerateModal()}
          owner={this.props.owner}
          createToast={this.props.createToast}
          groupState={this.props.groupState}
          getDonations={async (params: { groupId?: string; eventId?: string }) => {
            await this.getDonations(params);
          }}
        />

        <DonationDestinationModal
          owner={this.props.owner}
          donation={this.state.changeDonationDestination}
          show={!!this.state.changeDonationDestination}
          onClose={() => this.setState({ changeDonationDestination: null })}
          onDestinationChanged={() => this.refreshTables}
        />

        <Modal
          show={this.state.showAddDonation}
          onClose={() => {
            this.setState({ showAddDonation: false });
          }}
        >
          <AddDonation
            contact={this.props.contact}
            onClose={() => {
              this.setState({ showAddDonation: false });
              this.refreshTables();
            }}
            owner={this.props.owner}
            uiStateType={'default'}
          />
        </Modal>

        <IssuedTaxReceiptsExportModal
          show={this.state.exportTaxReceiptsAction !== null}
          exportType={this.state.exportTaxReceiptsAction}
          owner={this.props.owner}
          onClose={() => this.setState({ exportTaxReceiptsAction: null })}
        />

        <Prompt
          show={this.state.recurringPaymentToCancel}
          title="Cancel Recurring Payment"
          message="Are you sure you want to cancel this Recurring Payment?"
          yesMessage="Yes"
          yesStyle="delete"
          cancelMessage="No"
          onYes={async () => {
            await this.cancelRecurringPayment();
          }}
          onClose={() => this.setState({ recurringPaymentToCancel: false })}
        />
      </div>
    );
  }

  refreshTables() {
    this.setState({
      recurringRefreshTableIncrementor: this.state.recurringRefreshTableIncrementor + 1,
      onceRefreshTableIncrementor: this.state.onceRefreshTableIncrementor + 1,
    });
  }

  // TODO: Merge refund duplicated modal implementations.
  renderRefundModal() {
    if (this.state.order) {
      const exchangeRate = this.state.order.reporting_exchange_rate ?? 1;
      const currency = this.state.order.reporting_currency ?? defaultCurrency;

      return (
        <>
          <Modal
            onClose={() => {
              this.toggleRefundModal(false);
            }}
            show={this.state.showRefundModal}
          >
            <form
              className="refund"
              onSubmit={async (e) => {
                e.preventDefault();
                await this.refund();
              }}
            >
              <div className="refund-title">
                <h1>Refund Order</h1>
                <div>A copy of the receipt will be emailed to the customer.</div>
              </div>
              <div className="refund-info">
                <div className="sub-header">Order Summary</div>
                <div className="info">
                  <div className="info-part">
                    <span>Transaction type:</span>{' '}
                    <var data-var="transaction_type">
                      {this.state.order && this.state.order.transaction_type}
                    </var>
                  </div>
                  <div className="info-part">
                    <span>Transaction #:</span>{' '}
                    <var data-var="transaction_number">
                      {this.state.order && this.state.order.transaction_number}
                    </var>
                  </div>
                  <div className="info-part">
                    <span>Amount Charged:</span>
                    <var data-var="amount_charged">
                      {formatCurrency(
                        (this.state.order.amounts.total / 100) * exchangeRate,
                        currency,
                        this.props.locale,
                      )}
                    </var>
                  </div>
                  <div className="info-part">
                    <span>Refundable Amount:</span>
                    <var data-var="refundable_amount">
                      {formatCurrency(
                        this.calculateRefundableAmount() * exchangeRate,
                        currency,
                        this.props.locale,
                      )}
                    </var>
                  </div>
                </div>
                {!this.state.fullDonationRefund && (
                  <Fragment>
                    <div className="sub-header">Refund Details:</div>
                    <div className="refund-details">
                      <TextField
                        label="Refund Amount"
                        required={true}
                        value={this.state.refund_amount}
                        type="number"
                        name="refund_amount"
                        max={(
                          (this.state.order.amounts.amount +
                            this.state.order.amounts.payment_platform_fee) *
                          (this.state.order.amounts?.amount_multiplier || 1) *
                          exchangeRate
                        )
                          .toFixed(2)
                          .toString()}
                        step="0.01"
                        min="1"
                        onChange={(e) => {
                          handleInputChange(e, this);
                        }}
                      />
                    </div>
                  </Fragment>
                )}
                {this.state.order.amounts.tip > 0 && (
                  <div className="full-refund">
                    <i
                      onClick={() =>
                        this.setState({ fullDonationRefund: !this.state.fullDonationRefund })
                      }
                      className={
                        this.state.fullDonationRefund ? 'fad fa-toggle-on' : 'fad fa-toggle-off'
                      }
                    />
                    <span>Full Transaction Refund (includes tip)</span>
                  </div>
                )}
                <div className="refund-actions">
                  <Button text="Refund" />
                </div>
              </div>
            </form>
          </Modal>
        </>
      );
    }
  }
}

const mapStateToProps = (store: IAppState) => {
  return {
    groupState: store.groupState,
    eventState: store.eventState,
    locale: userSelectors.getCurrentLocale(store),
  };
};

const mapDispatchToProps = {
  createToast,
  getPledgesExport,
  sendConfirmationEmail,
  downloadGroupDonationReceipt,
  getDonationsExport,
  getDonationsExportEvent,
  getEventTeams,
  getEventIndividuals,
  createGroupDonationIntent,
  createEventDonationIntent,
  downloadEventDonationReceipt,
  cancelGroupRecurringDonation,
};

export default connect(mapStateToProps, mapDispatchToProps)(DonationList);
