import axios from 'axios';
import {
  ICampaign,
  IEventIndividual,
  IPage,
  IEventTeam,
  IGroup,
  IQuery,
  IUserRoleJoin,
  IForm,
  ICreateGroupFE,
  ICharityVerificationRequest,
  IGlobalGivingActiveProjectsReturn,
  IGlobalGivingPaymentDetailsRequest,
  IGlobalGivingMakePaymentReturn,
  IDiscoverFilters,
  IDiscoverItem,
  IGroupSummary,
  IEventSummaryFE,
  IGroupUpdateParams,
  IUserRole,
  IApplication,
  ITransactionSummary,
  ITransactionItemSummary,
} from '@gigit/interfaces';

import { swapRouteParams, routes } from '../helpers';
import { IFullForm } from '../reducers/group';

export namespace groupRequestActions {
  export async function getGroupByHandleOrId(handleOrId: string) {
    const response = await axios.get<IGroupSummary>(
      swapRouteParams(routes.GET_GROUP, { handleOrId: handleOrId }),
    );
    return response.data;
  }

  export async function getVolunteersExport(groupId: string): Promise<string> {
    const response = await axios.get(
      swapRouteParams(routes.GET_GROUP_VOLUNTEERS_EXPORT, { groupId }),
      { responseType: 'blob' },
    );
    return response.data;
  }

  export async function getGroups(query: IQuery | URLSearchParams) {
    const results = await axios.get<IGroup[]>(routes.GET_GROUPS, { params: query });
    return results.data;
  }

  export async function getPages(groupId: string) {
    const results = await axios.get<IPage[]>(swapRouteParams(routes.GET_GROUP_PAGES, { groupId }));
    return results.data;
  }

  export async function updateGroup(groupId: string, payload: IGroupUpdateParams): Promise<IGroup> {
    const response = await axios.put<IGroup>(
      swapRouteParams(routes.UPDATE_GROUP, { groupId }),
      payload,
    );
    return response.data;
  }

  export async function getGroupCampaign(groupId: string, campaignId: string): Promise<ICampaign> {
    const response = await axios.get<ICampaign>(
      swapRouteParams(routes.GET_GROUP_CAMPAIGN, { groupId, id: campaignId }),
    );
    return response.data;
  }

  export async function followGroup(groupId: string) {
    const response = await axios.post(swapRouteParams(routes.FOLLOW_GROUP, { groupId }));
    return response.data;
  }

  export async function unfollowGroup(groupId: string) {
    const response = await axios.post(swapRouteParams(routes.UNFOLLOW_GROUP, { groupId }));
    return response.data;
  }

  export async function createGroupRequest(
    group: ICreateGroupFE,
  ): Promise<ICharityVerificationRequest> {
    const response = await axios.post(routes.CREATE_GROUP_REQUEST, group);
    return response.data;
  }

  export async function createGroupPage(groupId: string, page: Partial<IPage>) {
    const response = await axios.post(swapRouteParams(routes.CREATE_GROUP_PAGE, { groupId }), page);
    return response.data;
  }

  export async function getGroupClassifications() {
    const response = await axios.get(routes.GET_GROUP_CLASSIFICATIONS);
    return response.data;
  }

  export async function getGroupEvents(
    groupId: string,
    queryParams: IQuery,
  ): Promise<IEventSummaryFE[]> {
    const response = await axios.get<IEventSummaryFE[]>(
      swapRouteParams(routes.GET_EVENTS_BY_GROUP, { groupId }),
      { params: queryParams },
    );
    return response.data;
  }

  export async function getGroupTeams(groupId: string, queryParams: IQuery): Promise<IEventTeam[]> {
    const response = await axios.get<IEventTeam[]>(
      swapRouteParams(routes.GET_GROUP_TEAMS, { groupId }),
      { params: queryParams },
    );
    return response.data;
  }

  export async function getGroupIndividuals(
    groupId: string,
    queryParams?: IQuery,
  ): Promise<IEventIndividual[]> {
    const response = await axios.get<IEventIndividual[]>(
      swapRouteParams(routes.GET_GROUP_INDIVIDUALS, { groupId }),
      { params: queryParams },
    );
    return response.data;
  }

  export async function getOnBehalfOfEnabledGroups(query?: URLSearchParams) {
    const response = await axios.get<IGroup[]>(routes.GET_GROUPS_OBOF_ALLOWED, { params: query });
    return response.data;
  }

  export async function getUserRole(groupId: string): Promise<IUserRoleJoin> {
    const response = await axios.get<IUserRoleJoin>(
      swapRouteParams(routes.GET_CURRENT_USER_GROUP_ROLE, { groupId }),
    );
    return response.data;
  }

  export async function createGroupForm(groupId: string, formData: IForm): Promise<IForm> {
    const response = await axios.post(
      swapRouteParams(routes.CREATE_FORM, { groupId: groupId }),
      formData,
    );
    return response.data;
  }

  export async function getGroupForm(groupId: string): Promise<IFullForm> {
    const response = await axios.get(swapRouteParams(routes.GET_GROUP_FORM, { groupId: groupId }));
    return response.data;
  }

  export async function getGroupPurchases(groupId: string, query?: URLSearchParams) {
    const response = await axios.get<ITransactionSummary[]>(
      swapRouteParams(routes.GROUP_PURCHASE, { groupId }),
      { params: query },
    );
    return response.data;
  }

  export async function getGroupPurchaseItems(groupId: string, transaction_id: string) {
    const response = await axios.get<ITransactionItemSummary[]>(
      swapRouteParams(routes.GET_PURCHASE_ITEMS, { groupId, transaction_id }),
    );
    return response.data;
  }

  export async function changeGroupStatus(
    groupId: string,
    status: string,
    title: string,
  ): Promise<IGroup> {
    const response = await axios.post(
      swapRouteParams(routes.CHANGE_GROUP_STATUS, { groupId: groupId }),
      { status: status },
    );
    return response.data;
  }

  export async function getGGProjects(id: string): Promise<IGlobalGivingActiveProjectsReturn[]> {
    const response = await axios.get<IGlobalGivingActiveProjectsReturn[]>(
      swapRouteParams(routes.GET_GG_PROJECTS, { groupId: id }),
    );
    return response.data;
  }

  export async function postGGDonation(
    id: string,
    payload: IGlobalGivingPaymentDetailsRequest,
  ): Promise<IGlobalGivingMakePaymentReturn> {
    const response = await axios.post<IGlobalGivingMakePaymentReturn>(
      swapRouteParams(routes.POST_GG_DONATION, { groupId: id }),
      payload,
    );
    return response.data;
  }

  export async function updateGGProjects(
    id: string,
  ): Promise<{ gg_active_projects_count: number }> {
    const response = await axios.put<{ gg_active_projects_count: number }>(
      swapRouteParams(routes.UPDATE_GG_PROJECTS, { groupId: id }),
    );
    return response.data;
  }

  export async function registerItem(groupId: string, id: string, itemId?: string) {
    const response = axios.post(
      swapRouteParams(routes.REGISTER_ITEM_GROUP, { groupId: groupId, id }),
      { itemId },
    );
    return response;
  }

  export async function discoverGroups(
    payload?: IDiscoverFilters,
    query?: URLSearchParams,
  ): Promise<IDiscoverItem[]> {
    const response = await axios.post<IDiscoverItem[]>(routes.DISCOVER_GROUPS_BASE, payload, {
      params: query,
    });
    return response.data;
  }

  export async function groupHasTransactions(id: string): Promise<{ has_transaction: boolean }> {
    const response = await axios.get(swapRouteParams(routes.GROUP_PAYMENT_SUMMARY, { id }));
    return response.data;
  }

  export async function getGroupMembers(id: string, query?: URLSearchParams): Promise<IUserRole[]> {
    const response = await axios.get(swapRouteParams(routes.GET_GROUP_MEMBERS, { groupId: id }), {
      params: query,
    });
    return response.data;
  }

  export async function getGroupApplicationsByUser(
    groupId: string,
    userId: string,
    query?: URLSearchParams,
  ): Promise<IApplication[]> {
    const response = await axios.get(
      swapRouteParams(routes.GET_GROUP_APPLICATIONS_BY_USER_HANDLE, { groupId, userId }),
      { params: query },
    );
    return response.data;
  }

  export async function approveGroupApplicant(
    groupId: string,
    applicationId: string,
  ): Promise<IApplication[]> {
    const response = await axios.post(
      swapRouteParams(routes.APPROVE_GROUP_APPLICANT, { groupId, id: applicationId }),
    );
    return response.data;
  }

  export async function rejectGroupApplicant(
    groupId: string,
    applicationId: string,
    reason: { rejectReason?: string },
  ): Promise<IApplication[]> {
    const response = await axios.post(
      swapRouteParams(routes.REJECT_GROUP_APPLICANT, { groupId, id: applicationId }),
      reason,
    );
    return response.data;
  }

  export async function getRecommendedCauses(): Promise<IGroup[]> {
    const response = await axios.get(routes.GET_RECOMMENDED_GROUPS);
    return response.data;
  }

  export async function updateRecommendedCauses(causes: string[]): Promise<IGroup[]> {
    const response = await axios.post(routes.UPDATE_RECOMMENDED_GROUPS, { causes });
    return response.data;
  }

  export async function removeRecommendedCauses(causes: string[]): Promise<IGroup[]> {
    const response = await axios.post(routes.REMOVE_RECOMMENDED_GROUPS, { causes });
    return response.data;
  }

  //APPROVE_GROUP_APPLICANT
}
