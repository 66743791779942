import React from 'react';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import axios from 'axios';
import {
  formatAddressLine,
  routes,
  swapRouteParams,
  errorHelpers,
  toastError,
} from '../../helpers';
import {
  IAddress,
  IEvent,
  IGig,
  IGroup,
  IHub,
  IEventSummaryFE,
  ConvertToFrontendType,
} from '@gigit/interfaces';
import { IOwnerObject, IToast } from '../../interfaces';
import queryString from 'query-string';

import { createToast } from '../../actions/toaster';

import Button from '../Button/Button';
import Loader from '../Loader/Loader';
import AddLocationModal from '../AddLocationModal/AddLocationModal';
import { Prompt } from '../Prompt/Prompt';

import './LocationManagement.scss';
import ContextMenu, { IContextMenuItem } from '../ContextMenu/ContextMenu';
import { uiConstants } from '../../constants';
import { updateGroup } from '../../actions/group';
import { updateEvent, IUpdateEventParams } from '../../actions/event';
import { updateGig } from '../../actions/gig';
import { IAppState } from '../../store';
import moment from 'moment';

interface IProps extends RouteComponentProps<any> {
  owner: IOwnerObject;
  createToast(toast: IToast): void;
  updateGroup(_payload: any, groupId: string): Promise<void>;
  updateEvent(event: IUpdateEventParams, callback?: (event: IEventSummaryFE) => void): void;
  updateGig(gig: any): void;
}

interface IState {
  locations: IAddress[];
  dataLoading: boolean;
  ownerId: string;
  showAddNewLocation: boolean;
  showDeleteConfirmation: boolean;
  locationToDelete: string | undefined;
  isManual: boolean;
}

class LocationManagement extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      locations: [],
      dataLoading: false,
      ownerId: '',
      showAddNewLocation: false,
      showDeleteConfirmation: false,
      locationToDelete: undefined,
      isManual: false,
    };
  }

  componentDidMount() {
    const params = queryString.parse(this.props.location.search);

    if (params.id) {
      //need this codtition when user redirected after clicking 'Manage Locations' on page component
      this.setState({ ownerId: params.id as string }, () => this.getLocations());
    } else {
      this.setState({ ownerId: this.props.owner.ownerId }, () => this.getLocations());
    }
  }

  getLocations() {
    const route: { [key: string]: string } = {
      group: swapRouteParams(routes.GET_GROUP_LOCATIONS, { groupId: this.state.ownerId }),
      event: swapRouteParams(routes.GET_EVENT_LOCATIONS, { eventId: this.state.ownerId }),
      gig: swapRouteParams(routes.GET_GIG_LOCATIONS, { gigId: this.state.ownerId }),
      company: swapRouteParams(routes.GET_GIG_LOCATIONS, { gigId: this.state.ownerId }),
    };

    this.setState({ dataLoading: true });
    axios
      .get<IAddress[]>(route[this.props.owner.ownerType])
      .then((response) => {
        this.setState({ locations: response.data });
      })
      .catch((error) => {
        const errorObject = errorHelpers.getErrorObject(error);
        const toast = toastError(errorObject.translatedMessage, 'Cannot get locations');
        this.props.createToast(toast);
      })
      .finally(() => {
        this.setState({ dataLoading: false });
      });
  }

  deleteLocation(id: string) {
    const route: { [key: string]: string } = {
      group: swapRouteParams(routes.DELETE_GROUP_LOCATION, {
        groupId: this.state.ownerId,
        locationId: id,
      }),
      event: swapRouteParams(routes.DELETE_EVENT_LOCATION, {
        eventId: this.state.ownerId,
        locationId: id,
      }),
      gig: swapRouteParams(routes.DELETE_GIG_LOCATION, {
        gigId: this.state.ownerId,
        locationId: id,
      }),
      company: swapRouteParams(routes.DELETE_GIG_LOCATION, {
        gigId: this.state.ownerId,
        locationId: id,
      }),
    };

    axios
      .delete<IAddress>(route[this.props.owner.ownerType])
      .then((response) => {
        this.setState({
          locations: this.state.locations.filter((location) => location.id !== response.data.id),
        });
      })
      .catch((error) => {
        const errorObject = errorHelpers.getErrorObject(error);
        const toast = toastError(errorObject.translatedMessage, 'Cannot delete locations');
        this.props.createToast(toast);
      })
      .finally(() => {
        this.setState({ locationToDelete: undefined });
      });
  }

  renderDefaultStatus(item: IAddress) {
    let mainObject: IHub | IGroup | IEvent | IGig | null = null;
    let isDefault = false;
    switch (this.props.owner.ownerType) {
      case uiConstants.ownerType.hub:
        mainObject = this.props.owner.object as IHub;
        if (mainObject.default_location?.location_id === item.id) {
          isDefault = true;
        }
        break;
      case uiConstants.ownerType.group:
        mainObject = this.props.owner.object as IGroup;
        if (mainObject.default_location?.location_id === item.id) {
          isDefault = true;
        }
        break;
      case uiConstants.ownerType.event:
        mainObject = this.props.owner.object as IEvent;
        if (mainObject.default_location?.location_id === item.id) {
          isDefault = true;
        }
        break;
      case uiConstants.ownerType.gig:
        mainObject = this.props.owner.object as IGig;
        if (mainObject.default_location?.location_id === item.id) {
          isDefault = true;
        }
        break;
    }

    if (isDefault) {
      return <span className="default-status default">Default</span>;
    } else {
      return <span className="default-status not-default">Not Default</span>;
    }
  }

  makeLocationDefault(item: IAddress) {
    // let mainObject: IHub | IGroup | IEvent | IGig | null = null;
    let isDefault = false;
    switch (this.props.owner.ownerType) {
      case uiConstants.ownerType.hub:
        let hubObject = this.props.owner.object as IHub;
        if (hubObject.default_location?.location_id === item.id) {
          isDefault = true;
        }
        return;
      case uiConstants.ownerType.group:
        let groupObject = this.props.owner.object as IGroup;
        if (groupObject.default_location?.location_id === item.id) {
          isDefault = true;
        }
        if (isDefault) {
          // Make Toast saying location is already a default one
          return;
        }

        if (groupObject.id) {
          this.props.updateGroup(
            { default_location: { ...item, location_id: item.id } },
            groupObject.id,
          );
        }
        return;
      case uiConstants.ownerType.event:
        let eventObject = this.props.owner.object as IEvent;
        if (eventObject.default_location?.location_id === item.id) {
          isDefault = true;
        }
        if (isDefault) {
          // Make Toast saying location is already a default one
          return;
        }

        if (eventObject.id) {
          let stringCreatedAt = moment(item.created_at).toString();
          let stringUpdatedAt = moment(item.updated_at).toString();

          let itemFE: ConvertToFrontendType<IAddress> = {
            ...item,
            created_at: stringCreatedAt,
            updated_at: stringUpdatedAt,
            location_id: item.id,
          };
          this.props.updateEvent({ default_location: itemFE, id: eventObject.id });
        }
        return;
      case uiConstants.ownerType.gig:
        let gigObject = this.props.owner.object as IGig;
        if (gigObject.default_location?.location_id === item.id) {
          isDefault = true;
        }
        if (isDefault) {
          // Make Toast saying location is already a default one
          return;
        }

        if (gigObject.id) {
          this.props.updateGig({
            ...gigObject,
            default_location: { ...item, location_id: item.id },
          });
        }

        return;
      default:
        return;
    }
  }

  render() {
    return (
      <div className="LocationManagement section-wrap">
        <div className="section-title">
          <div className="section-inner-title">Locations</div>
          <div className="section-create">
            <Button
              text="New Location"
              icon="fal fa-plus"
              onClick={() => {
                this.setState({ showAddNewLocation: true });
              }}
            />
          </div>
        </div>
        <div className="section-inner">
          <div className="LocationManagement-list">
            <div className="list">
              <div className="headers">
                <div className="col title">Title</div>
                <div className="col address">Address</div>
                <div className="col default">Is Default</div>
                <div className="col actions"></div>
              </div>
              <div className="list-inner">
                <Loader loading={this.state.dataLoading} />
                <div className="list-rows">
                  {this.state.locations.map((item, index) => {
                    let _menuItems: IContextMenuItem[] = [
                      {
                        label: 'Delete Location',
                        onClick: () => {
                          this.setState({ locationToDelete: item.id });
                        },
                      },
                      {
                        label: 'Make location default',
                        onClick: () => {
                          this.makeLocationDefault(item);
                        },
                      },
                    ];
                    return (
                      <div
                        key={index}
                        className="row"
                      >
                        <div
                          className="col title"
                          notranslate="yes"
                        >
                          {item.title}
                        </div>
                        <div
                          className="col address"
                          notranslate="yes"
                        >
                          ({formatAddressLine(item)})
                        </div>
                        <div
                          className="col default"
                          notranslate="yes"
                        >
                          {this.renderDefaultStatus(item)}
                        </div>
                        <div className="col actions">
                          <i className="fal fa-ellipsis-h-alt" />
                          <ContextMenu
                            onMouseLeave={() => {}}
                            showMenu={true}
                            menuItems={_menuItems}
                          />

                          {/*<Button text="Delete Location" onClick={() => { this.setState({ locationToDelete: item.id }) }} />*/}
                        </div>
                      </div>
                    );
                  })}
                </div>
                {this.state.locations.length === 0 && !this.state.dataLoading && (
                  <div className="not-found">No locations found</div>
                )}
              </div>
            </div>
          </div>

          {this.state.showAddNewLocation && (
            <AddLocationModal
              owner={this.props.owner}
              onClose={() => this.setState({ showAddNewLocation: false })}
              onLocationAdded={(location: IAddress) => {
                if (this.state.locations.length == 0) {
                  this.makeLocationDefault(location);
                }
                this.setState({ locations: [...this.state.locations, location] });
              }}
            />
          )}

          <Prompt
            show={!!this.state.locationToDelete}
            title="Delete Location"
            message="Are you sure you want to delete location?"
            yesMessage="Yes"
            yesClass="fa fa-trash"
            yesStyle="delete"
            cancelMessage="Cancel"
            onYes={() => this.deleteLocation(this.state.locationToDelete as string)}
            onClose={() => this.setState({ locationToDelete: undefined })}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (store: IAppState) => {
  return {
    groupState: store.groupState,
  };
};

const mapDispatchToProps = {
  createToast,
  updateGroup,
  updateEvent,
  updateGig,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LocationManagement));
