import './HubEvents.scss';
import React, { useRef, useState } from 'react';
import { useEffect } from 'react';
import { ICause, IEvent, IEventFE, IEventSummaryFE, IGroup, IHub, IPage } from '@gigit/interfaces';
import {
  hubRequestActions,
  causeRequestActions,
  userRequestActions,
} from '../../../requestActions';
import useToastDispatcher from '../../../hooks/useToaster';
import HubFilter from '../Shared/HubFilterBar/HubFilterBar';
import CausesFilter from '../Shared/CausesFilter/CausesFilter';
import Loader from '../../Loader/Loader';
import { useCallback } from 'react';
import { formatQuery, IQueryParams, generateEmptyCards, typeHelpers } from '../../../helpers';
import { ISortSettings } from '../../../interfaces';
import { uiConstants } from '../../../constants';
import { IPopupMenuItem, IShowPopupConfig } from '../../shared/PopupMenu/PopupMenu';
import HubSharedDetailsModal from '../HubModals/HubSharedDetailsModal/HubSharedDetailsModal';
import {
  CardCommonEvent,
  EStyleTypeCardCommonEvent,
  EViewTypeCardCommonEvent,
} from '../../CardCommonEvent/CardCommonEvent';
import { Constants } from '@gigit/constants';
import { IThemeStyleObjectsByType } from '../Hub';
import HubToggleButtons from '../Shared/HubToggleButtons/HubToggleButtons';
import { useSelector } from 'react-redux';
import { IAppState } from '../../../store';
import { Link } from 'react-router-dom';
import { userSelectors } from '../../../selectors/user';
import EventsLogoDefault from '../../../assets/event-icon-masks-light.svg';
import { IDropdownOption } from '../../UserDashboard/Applications/Applications';

const sortByNew = { label: 'Newest', value: 'desc' } as { label: string; value: 'asc' | 'desc' };
const sortByOld = { label: 'Oldest', value: 'asc' } as { label: string; value: 'asc' | 'desc' };

interface IHubEventsProps {
  hub: IHub | null;
  userHasEditPermissions: boolean;
  page: IPage | null;
  theme: IThemeStyleObjectsByType;
}

type HubEventPageType = 'event' | 'team' | 'individual' | 'all';

type HubEventPageOptions = {
  [key in HubEventPageType]: IDropdownOption;
};

function HubEvents(props: IHubEventsProps) {
  const [searchValue, setSearchValue] = useState<string | undefined>(undefined);
  const [events, setEvents] = useState<IEventFE[]>([]);
  const [partnerEvents, setPartnerEvents] = useState<IEventFE[]>([]);
  const [causes, setCauses] = useState<string[]>([]);
  const [hubPartners, setHubPartners] = useState<IGroup[]>([]);
  const [selectedCauses, setSelectedCauses] = useState<string[]>([]);
  const [userRole, setUserRole] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { dispatchToastError } = useToastDispatcher();
  const [causesMaster, setCausesMaster] = useState<ICause[]>();
  const [searchDebounce, setSearchDebounce] = useState<ReturnType<typeof setTimeout>>();
  const [hasMore, setHasMore] = useState<boolean>(false);
  const [allowNonMember, setAllowNonMember] = useState<boolean>(
    props.hub?.public_access_to_events_and_gigs || false,
  );
  const [page, setPage] = useState<number>(0);
  const [showContextMenuSortBy, setShowContextMenuSortBy] = useState<boolean>(false);
  const [showContextMenuFundraiserType, setShowContextMenuFundraiserType] =
    useState<boolean>(false);
  const [sortValue, setSortValue] = useState<ISortSettings[] | null>([
    { id: 'created_at', order: sortByNew.value },
  ]);
  const [sortBy, setSortBy] = useState<{ label: string; value: 'asc' | 'desc' }>(sortByNew);
  const [selectedEvent, setSelectedEvent] = useState<IEventSummaryFE>();
  const [showModal, setShowModal] = useState<boolean>(false);
  const allowedRoles = [uiConstants.role.admin, uiConstants.role.member];
  const [eventType, setEventType] = useState<string>('company');
  const [toggleButtonList, setToggleButtonList] = useState<any>([]);
  const hubId = props.hub?.id;
  const isUserLoggedIn = useSelector((state: IAppState) =>
    userSelectors.isUserAuthenticated(state),
  );

  let observer: React.MutableRefObject<IntersectionObserver | undefined> =
    useRef<IntersectionObserver>();
  const lastPartnerItem = useCallback(
    (node) => {
      if (isLoading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (
          entries[0]?.isIntersecting &&
          hasMore &&
          events.length >= parseInt(uiConstants.hubs.listViewPageSize)
        ) {
          setPage((prevPageNumber) => prevPageNumber + 1);
          const params = getQueryParamsAsURL();
          if (hubId) getEvents(hubId, params);
        }
      });

      if (node) observer.current.observe(node);
    },
    [isLoading, hasMore, hubId],
  );

  const fundraiserFilterOptions: HubEventPageOptions = {
    event: { label: 'Event', value: Constants.pages_type.event },
    team: { label: 'Team', value: Constants.pages_type.team },
    individual: { label: 'Individual', value: Constants.pages_type.individual },
    all: { label: 'All Events', value: Constants.pages_type.all },
  };

  const [filterFundraiserValue, setFilterFundraiserValue] = useState<IDropdownOption>(
    fundraiserFilterOptions.all,
  );

  async function getHubRoles() {
    const userRoleName = await userRequestActions.getUserHubRole(props.hub?.id || '');
    setUserRole(userRoleName.role_name);
  }

  async function setEventList() {
    setToggleButtonList([
      ...((allowedRoles.includes(userRole) || props.hub?.public_access_to_events_and_gigs) &&
      events.length
        ? [
            {
              text: 'Company Events',
              active: eventType === 'company',
              eventType: eventType,
              onClick: () => {
                setEventType('company');
              },
            },
          ]
        : []),
      ...(hubPartners.length && partnerEvents.length
        ? [
            {
              text: 'Partner Events',
              active: eventType === 'partner',
              onClick: () => {
                setEventType('partner');
              },
            },
          ]
        : []),
    ]);
  }

  useEffect(() => {
    if (hubId) {
      getHubRoles();
      getCauses();
      getHubPartners(hubId);
      getHubEventsCauses(hubId);
    }
  }, [hubId, eventType]);

  useEffect(() => {
    setEventList();
  }, [eventType, userRole, events, partnerEvents, hubId]);

  useEffect(() => {
    if (hubId) {
      setPage(0);
      getEvents(hubId, getQueryParamsAsURL());
      getPartnerEvents(hubId, getQueryParamsAsURL());
    }
  }, [hubId, selectedCauses, sortValue, searchValue, eventType, filterFundraiserValue]);

  useEffect(() => {
    handleSortBy();
  }, [sortBy]);

  function handleSortBy() {
    setPage(0);
    setSortValue([{ id: 'created_at', order: sortBy.value }]);
  }

  async function getEvents(id: string, params?: URLSearchParams) {
    setIsLoading(true);
    try {
      let events = await hubRequestActions.getHubEvents(id, params);
      setHasMore(events.length > 0);
      if (!events.length) setEventType('partner');
      if (page === 0) {
        setEvents(events);
      } else {
        setEvents((previousEvents) => [...previousEvents, ...events]);
      }
    } catch (error) {
      dispatchToastError(error, 'Hub Events');
    } finally {
      setIsLoading(false);
    }
  }
  async function getPartnerEvents(id: string, params?: URLSearchParams) {
    setIsLoading(true);
    try {
      let partnerEvents = await hubRequestActions.getHubPartnerEvents(id, params);
      setHasMore(partnerEvents.length > 0);
      if (page === 0) {
        setPartnerEvents(partnerEvents);
      } else {
        setPartnerEvents((previousEvents) => [...previousEvents, ...partnerEvents]);
      }
    } catch (error) {
      dispatchToastError(error, 'Hub Events');
    } finally {
      setIsLoading(false);
    }
  }

  async function getHubEventsCauses(id?: string) {
    try {
      if (id) {
        const eventCauses = await hubRequestActions.getHubEventCauses(id);
        const causes = eventCauses.map(({ cause }) => cause);
        setCauses(causes);
      }
    } catch (error) {
      dispatchToastError(error, 'Hub Partners Focus Areas');
    }
  }

  async function getHubPartners(id?: string) {
    try {
      typeHelpers.assertNotNullOrUndefined(id, 'Expected Hub Type');
      const result = await hubRequestActions.getHubPartners(id);
      setHubPartners(result);
      return result;
    } catch (error) {
      dispatchToastError(error, 'Get Benefiting Causes');
      return [];
    }
  }

  function handleCauseSelection(updatedSelections: string[]) {
    setPage(0);
    setSelectedCauses(updatedSelections);
  }

  function getQueryParams(): IQueryParams {
    let queryParams: IQueryParams = {
      sort: sortValue || [],
      type: filterFundraiserValue.value,
      limit: uiConstants.hubs.listViewPageSize,
      skip: (page * parseInt(uiConstants.hubs.listViewPageSize)).toString(),
    };
    const filters = selectedCauses.map((cause) => getCauseId(cause) || '');

    // Filter out events that are not active or published on BE
    queryParams.filters = [
      {
        value: `${Constants.event_status.active}|${Constants.event_status.published}`,
        label: 'status.code',
        id: 'status.code',
        type: 'text',
      },
    ];

    if (searchValue) {
      queryParams.search = searchValue;
    } else {
      queryParams.search = '';
    }

    if (filters.length > 0) {
      queryParams.common_filters = { causes: filters };
    }

    return queryParams;
  }

  function getQueryParamsAsURL(): URLSearchParams {
    const queryParams = getQueryParams();
    return formatQuery(queryParams);
  }

  async function getCauses() {
    try {
      const causeList = await causeRequestActions.getCauses();
      setCausesMaster(causeList);
    } catch (error) {
      setCausesMaster([]);
      dispatchToastError(error, 'Focus Areas');
    }
  }

  function getCauseId(label: string) {
    return causesMaster?.find((cause: ICause) => cause?.cause === label)?.id;
  }

  // Config
  const popupMenuConfigSortBy: IShowPopupConfig = {
    showMenu: showContextMenuSortBy,
    setShowMenu: setShowContextMenuSortBy,
    position: {
      type: 'bottom',
    },
  };

  const popupMenuConfigFundraiserType: IShowPopupConfig = {
    showMenu: showContextMenuFundraiserType,
    setShowMenu: setShowContextMenuFundraiserType,
    position: {
      type: 'bottom',
    },
  };

  const contextMenuItemsSortBy: IPopupMenuItem[] = [
    {
      id: uiConstants.sortOptions.createdAtAscending,
      label: sortByNew.label,
      isSelected: sortBy.value === sortByNew.value,
      onClick: () => setSortBy(sortByNew),
    },
    {
      id: uiConstants.sortOptions.createdAtDescending,
      label: sortByOld.label,
      isSelected: sortBy.value === sortByOld.value,
      onClick: () => setSortBy(sortByOld),
    },
  ];

  const contextMenuItemsFundraiserType: IPopupMenuItem[] = [
    {
      id: fundraiserFilterOptions.event.value,
      label: fundraiserFilterOptions.event.label,
      isSelected: filterFundraiserValue.value === fundraiserFilterOptions.event.value,
      onClick: () => {
        setFilterFundraiserValue(fundraiserFilterOptions.event);
        setShowContextMenuFundraiserType(false);
      },
    },
    {
      id: fundraiserFilterOptions.team.value,
      label: fundraiserFilterOptions.team.label,
      isSelected: filterFundraiserValue.value === fundraiserFilterOptions.team.value,
      onClick: () => {
        setFilterFundraiserValue(fundraiserFilterOptions.team);
        setShowContextMenuFundraiserType(false);
      },
    },
    {
      id: fundraiserFilterOptions.individual.value,
      label: fundraiserFilterOptions.individual.label,
      isSelected: filterFundraiserValue.value === fundraiserFilterOptions.individual.value,
      onClick: () => {
        setFilterFundraiserValue(fundraiserFilterOptions.individual);
        setShowContextMenuFundraiserType(false);
      },
    },
    {
      id: fundraiserFilterOptions.all.value,
      label: fundraiserFilterOptions.all.label,
      isSelected: filterFundraiserValue.value === fundraiserFilterOptions.all.value,
      onClick: () => {
        setFilterFundraiserValue(fundraiserFilterOptions.all);
        setShowContextMenuFundraiserType(false);
      },
    },
  ];

  function handleSelectedCard(id: string) {
    let selectedEvent = events.find((event) => event.id == id);
    setSelectedEvent(selectedEvent);
    setShowModal(true);
  }
  return (
    <div className="HubEvents">
      <HubFilter
        header={props.page?.label || 'Events'}
        searchValue={searchValue}
        onSearch={setSearchValue}
        sortByConfig={[
          {
            menuItems: contextMenuItemsSortBy,
            showMenuConfig: popupMenuConfigSortBy,
            className: 'sortBy',
            label: 'Sort By',
            valueLabelIcon: 'far fa-clock',
            valueLabel: sortBy.label,
            popupMenuClass: 'HubEvents-popupMenu-sortBy',
            showMenu: showContextMenuSortBy,
            onClick: () => {
              setShowContextMenuSortBy(true);
            },
            onSelect: () => {
              setShowContextMenuSortBy(false);
            },
          },
        ]}
        filterByFundraiserConfig={[
          {
            menuItems: contextMenuItemsFundraiserType,
            showMenuConfig: popupMenuConfigFundraiserType,
            className: 'sortBy',
            label: 'Filter',
            valueLabel: filterFundraiserValue.label,
            popupMenuClass: 'HubEvents-popupMenu-sortBy',
            showMenu: showContextMenuFundraiserType,
            onClick: () => {
              setShowContextMenuFundraiserType(true);
            },
            onSelect: () => {
              setShowContextMenuFundraiserType(false);
            },
          },
        ]}
        searchPlaceholder="Search by Cause"
      />
      <CausesFilter
        causes={causes}
        selectedCauses={selectedCauses}
        causesMaster={causesMaster || []}
        onSelectCause={handleCauseSelection}
      />
      <hr />
      <HubToggleButtons
        activeStyle={props.theme.secondaryBgWithText}
        buttons={toggleButtonList}
      />

      {!isLoading && eventType === 'partner' && partnerEvents.length > 0 && (
        <div className="grid-container">
          {partnerEvents.map((item, index) => {
            return (
              <React.Fragment key={index}>
                {
                  <CardCommonEvent
                    event={item as unknown as IEventSummaryFE}
                    styleType={EStyleTypeCardCommonEvent.HUB}
                    viewType={EViewTypeCardCommonEvent.GRID}
                    openDetailModal={(event_id: string) => {
                      handleSelectedCard(event_id);
                    }}
                  />
                }
              </React.Fragment>
            );
          })}

          {partnerEvents.length % 3 !== 0 &&
            generateEmptyCards(3 - (partnerEvents.length % 3), Constants.object_type.event)}
        </div>
      )}

      {!isLoading && eventType === 'company' && events.length > 0 && (
        <div className="grid-container">
          {events.map((item, index) => {
            return (
              <React.Fragment key={index}>
                {
                  <CardCommonEvent
                    event={item as unknown as IEventSummaryFE}
                    styleType={EStyleTypeCardCommonEvent.HUB}
                    viewType={EViewTypeCardCommonEvent.GRID}
                    openDetailModal={(event_id: string) => {
                      handleSelectedCard(event_id);
                    }}
                  />
                }
              </React.Fragment>
            );
          })}

          {events.length % 3 !== 0 &&
            generateEmptyCards(3 - (events.length % 3), Constants.object_type.event)}
        </div>
      )}

      {!isLoading && events.length < 1 && partnerEvents.length < 1 ? (
        <div className="empty-state">
          <div className="img-container">
            <img
              src={EventsLogoDefault}
              alt="events empty state"
            />
          </div>
          <p>There are no events currently available</p>
          {isUserLoggedIn && props.userHasEditPermissions && (
            <Link to={`/company/${props.hub?.handle}/admin?t=benefiting-causes`}>
              Try adding more causes
            </Link>
          )}
        </div>
      ) : !isLoading && partnerEvents.length < 1 && eventType === 'partner' ? (
        <div className="empty-state">
          <div className="img-container">
            <img
              src={EventsLogoDefault}
              alt="events empty state"
            />
          </div>
          <p>There are no partner events currently available</p>
          {isUserLoggedIn && props.userHasEditPermissions && (
            <Link to={`/company/${props.hub?.handle}/admin?t=benefiting-causes`}>
              Try adding more causes
            </Link>
          )}
        </div>
      ) : (
        !isLoading &&
        events.length < 1 &&
        eventType === 'company' && (
          <div className="empty-state">
            <div className="img-container">
              <img
                src={EventsLogoDefault}
                alt="events empty state"
              />
            </div>
            <p>There are no events currently available</p>
            {isUserLoggedIn && props.userHasEditPermissions && (
              <Link to={`/company/${props.hub?.handle}/admin?t=benefiting-causes`}>
                Try adding more causes/events
              </Link>
            )}
          </div>
        )
      )}

      {isLoading && <Loader loading={isLoading} />}
      <HubSharedDetailsModal
        hub={props.hub}
        type={uiConstants.ownerType.event}
        show={showModal}
        selectedItem={selectedEvent ? selectedEvent : null}
        onClose={() => setShowModal(false)}
      />
    </div>
  );
}

export default HubEvents;
