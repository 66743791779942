import axios from 'axios';
import moment, { Moment } from 'moment';
import { Config } from '@gigit/config';
import {
  IMediaItem,
  IRole,
  ICause,
  IConversationSummary,
  IUserRole,
  IAddress,
  IAddressBase,
} from '@gigit/interfaces';
import { Constants } from '@gigit/constants';
import { IOptions } from '../components/Dropdown/Dropdown';
import {
  IActiveFilter,
  IOwnerObject,
  ISortSettings,
  IToast,
  IToastAction,
  OwnerObjectTypes,
} from '../interfaces';
import React, { ReactNode, RefObject } from 'react';
import { uiConstants } from '../constants';
import { localizeHelpers } from '../localizeHelpers';
import { createToast } from '../actions/toaster';
import typeHelpers from './typeHelpers';

export interface IStringMap {
  [key: string]: any;
}

export interface IRecommendedFocusAreas extends ICause {
  selected?: boolean;
}

/** Different possible states of validating a handle (ie: user handle, group handle, etc...). */
export enum ValidateHandleStatus {
  NORMAL,
  VALIDATING,
  VALIDATED,
  ERROR,
}

/** These are references to Backend Routes. If a backend route path changes, it should be changed here too. */
export const routes = {
  ACCOUNTS: {
    CONNECT_ACCOUNT: '/accounts/authorize',
    AUTHORIZE_TWITCH: '/accounts/authorize_twitch',
    AUTHORIZE_STRAVA: '/accounts/authorize_strava',
    GET_OBJECT_STRIPE_ACCOUNT_AUTHORIZE_LINK:
      '/accounts/for/:object_type/:object_id/account_authorization_link',
    GET_OBJECT_STRIPE_ACCOUNT_LINK: '/accounts/for/:object_type/:object_id/account_link/:accountId',
    GET_OBJECT_ACCOUNTS: '/accounts/for/:object_type/:object_id/accounts',
    GET_OBJECT_ACCOUNTS_WITH_DETAILS: '/accounts/for/:object_type/:object_id/accounts/details',
    GET_OBJECT_CURRENT_ACCOUNT_PUBLIC_SUMMARY:
      '/accounts/for/:object_type/:object_id/accounts/current/summary_public',
    GET_OBJECT_EXTERNAL_ACCOUNT_DEFAULT:
      '/accounts/for/:object_type/:object_id/accounts/:accountId/external_accounts/default',
    SET_OBJECT_ACCOUNT_CURRENT:
      '/accounts/for/:object_type/:object_id/accounts/:accountId/set_current',
    CREATE_OBJECT_EXTERNAL_ACCOUNT:
      '/accounts/for/:object_type/:object_id/accounts/:accountId/external_accounts',
    GET_OBJECT_DONATION_PAYMENT_METHOD_DETAILS:
      '/accounts/for/:object_type/:object_id/donations/:transaction_id/payment_method_details',
    GET_OBJECT_ACCOUNT_PAYOUTS: '/accounts/for/:object_type/:object_id/accounts/:accountId/payouts',
    GET_OBJECT_ACCOUNT_PAYOUT_TRANSACTIONS:
      '/accounts/for/:object_type/:object_id/accounts/:accountId/payouts/:payoutId/transactions',
    GET_OBJECT_ACCOUNT_PAYOUT_KPI:
      '/accounts/for/:object_type/:object_id/accounts/:accountId/payouts/:payoutId/kpi',
  },
  /** These routes return presigned URLs for front end to handle viewing, uploading, or deleting S3 attachment files */
  ATTACHMENTS: {
    GET_OFFLINE_DONATION_TRANSACTION_ATTACHMENT_URL:
      '/donations/offline_donations/:transaction_id/attachments/:attachment_id',
    CREATE_OFFLINE_DONATION_ATTACHMENT_URL:
      '/donations/offline_donations/:transaction_id/attachments',
    DELETE_OFFLINE_DONATION_ATTACHMENT_URL:
      '/donations/offline_donations/:transaction_id/attachments/:attachment_id',
  },

  REGISTER: '/auth/register',
  LOGIN: '/auth/login',
  LOGOUT: '/auth/logout',
  GET_PROFILE: '/users/:handleOrId',
  GET_PROFILE_PAGES: '/users/:userId/pages',
  GET_PROFILE_PAGE_COMPONENTS: '/users/:userId/pages/:pageId/content',
  DELETE_PROFILE_PAGE: '/users/current/pages/:pageId',
  UPDATE_PROFILE_PAGE: '/users/current/pages/:pageId',
  CREATE_USER_PAGE_COMPONENT: '/users/current/pages/:pageId/components',
  DELETE_USER_PAGE_COMPONENT: '/users/current/pages/:pageId/components/:componentId',
  UPDATE_USER_PAGE_COMPONENT: '/users/current/pages/:pageId/components/:componentId',
  GET_USER_HUBS: '/users/current/hubs',
  GET_ACTIVE_USER_HUBS: '/users/current/hubs/active',
  GET_PROFILE_STATS: '/users/:userId/user_stats',
  UPDATE_USER: '/users/current',
  USER_VOLUNTEERING_HOURS: 'volunteering/logged_volunteer_hours/my_hours',
  USER_VOLUNTEERING_HOURS_BULK: 'volunteering/logged_volunteer_hours/my_hours/bulk',
  USER_VOLUNTEERING_HOURS_EDIT: 'volunteering/logged_volunteer_hours/my_hours/:logged_hour_id',
  USER_VOLUNTEERING_HOURS_ADD: 'volunteering/logged_volunteer_hours/for/gig/:object_id',
  USER_VOLUNTEERING_HOURS_ADD_BULK: 'volunteering/logged_volunteer_hours/for/gig/:object_id/bulk',
  USER_VOLUNTEERING_HOURS_VERIFY: 'volunteering/logged_volunteer_hours/for/gig/:gigId/approve/bulk',
  USER_VOLUNTEERING_HOURS_FOR_GIG: 'volunteering/logged_volunteer_hours/for/gig/:gigId',
  USER_UNIQUE_HANDLE: '/users/validate/:handle',
  CHANGE_USER_HANDLE: '/users/current/handle',
  GET_USER_ALL: '/users',
  GET_USER_GROUPS: '/users/current/groups',
  GET_USER_GROUPS_MONETIZED: '/users/current/groups',
  GET_CURRENT_USER_GROUP_ROLE: '/users/current/groups/:groupId/role',
  GET_CURRENT_USER_EVENT_ROLE: '/users/current/events/:eventId/role',
  GET_CURRENT_USER_HUB_ROLE: '/users/current/hubs/:hubId/role',
  GET_USER_INVITES: '/users/current/invites',
  CREATE_GROUP_REQUEST: '/groups',
  GROUP_UNIQUE_HANDLE: '/groups/validate/:handle',
  GET_GROUP: '/groups/:handleOrId',
  CHANGE_GROUP_HANDLE: '/groups/:groupId/handle',
  GET_GROUPS: '/groups',
  GET_GROUP_ROLES: '/groups/:groupId/roles',
  GET_GROUP_PAGES: '/groups/:groupId/pages',
  GET_GROUP_GIGS_PUBLIC: '/groups/:groupId/gigs/public',
  GET_GROUP_TEAMS: '/groups/:groupId/teams',
  GET_GROUP_INDIVIDUALS: '/groups/:groupId/individuals',
  FIND_HUB_MEMBER: '/hubs/:hubId/members/:userId',
  CREATE_GROUP_PAGE: '/groups/:groupId/pages',
  GET_GROUP_PAGE_COMPONENTS: '/groups/:groupId/pages/:pageId/content',
  CREATE_GROUP_PAGE_COMPONENT: '/groups/:groupId/pages/:pageId/components',
  DELETE_GROUP_PAGE_COMPONENT: '/groups/:groupId/pages/:pageId/components/:componentId',
  UPDATE_GROUP_PAGE_COMPONENT: '/groups/:groupId/pages/:pageId/components/:componentId',
  UPDATE_GROUP_ROLE: '/groups/:groupId/roles/:id',
  UPDATE_GROUP: '/groups/:groupId',
  UPDATE_GIG: '/gigs/:gigId',
  SET_VOLUNTEER_FLAG: '/volunteering/for/gig/:gigId/volunteers/:userId/set_volunteer_flag',
  UPDATE_GROUP_PAGE: '/groups/:groupId/pages/:id',
  DELETE_GROUP_PAGE: '/groups/:groupId/pages/:id',
  JOIN_GROUP: '/groups/:groupId/join',
  FOLLOW_GROUP: '/groups/:groupId/follow',
  UNFOLLOW_GROUP: '/groups/:groupId/unfollow',
  FOLLOW_HUB: '/hubs/:hubId/follow',
  UNFOLLOW_HUB: '/hubs/:hubId/unfollow',
  GET_GG_PROJECTS: '/groups/:groupId/getGgActiveProjects',
  POST_GG_DONATION: '/groups/:groupId/globalGivingDonation',
  UPDATE_GG_PROJECTS: '/groups/:groupId/updateGgActiveProjects',
  REFRESH_TOKEN: '/auth/refresh',
  IS_LOGGED_IN: '/auth/is_logged_in',
  CHECK_AUTH_TYPE: '/auth/type/:email',
  GET_CURRENT_USER: '/auth/user/current',
  SEARCH_USER: '/users?search=:search',
  GET_CONVERSATIONS: '/chat/conversations',
  GET_CHAT_USERS: '/chat/users',
  ADD_TO_CHAT: '/chat/conversations/:convoId/users',
  REMOVE_FROM_CHAT: '/chat/conversations/:convoId/users/:userId',
  START_CONVERSATION: '/chat/conversations',
  GET_CONVO_MESSAGES: '/chat/conversations/:convoId/messages',
  SEND_MESSAGE: '/chat/conversations/:convoId/messages',
  GET_CONVO_MEMBERS: '/chat/conversations/:convoId/users',
  GET_BLOCKED_USERS: '/chat/blocked',
  BLOCK_CHAT_USER: '/chat/block/:userId',
  UNBLOCK_CHAT_USER: '/chat/unblock/:userId',
  SEARCH_CHAT_USERS: '/chat/users?search=:search',
  GET_UNREAD_NOTIFICATIONS: '/users/current/notifications?read=false',
  GET_NOTIFICATIONS: '/users/current/notifications',
  FOLLOW_USER: '/users/:id/follow',
  UNFOLLOW_USER: '/users/:id/unfollow',
  UPLOAD: '/api/store',
  MARK_READ: '/users/current/notifications/:notificationId',
  MARK_MULTIPLE_READ: '/users/current/notifications',
  CREATE_EVENT: '/groups/:groupId/events',
  CREATE_HUB_EVENT: '/hubs/:id/events',
  GET_EVENTS_BY_GROUP: '/groups/:groupId/events',
  CREATE_EVENT_PAGE: '/events/:eventId/pages',
  GET_EVENT_PAGES: '/events/:eventId/pages',
  UPDATE_EVENT_PAGE: '/events/:eventId/pages/:pageId',
  GET_EVENT: '/events/:handleOrId',
  UPDATE_EVENT: '/events/:eventId',
  CHANGE_EVENT_HANDLE: '/events/:eventId/handle',
  EVENT_UNIQUE_HANDLE: '/events/validate/:handle',
  DELETE_EVENT_PAGE: '/events/:eventId/pages/:pageId',
  CREATE_EVENT_PAGE_COMPONENT: '/events/:eventId/pages/:pageId/components',
  UPDATE_EVENT_PAGE_COMPONENT: '/events/:eventId/pages/:pageId/components/:componentId',
  DELETE_EVENT_PAGE_COMPONENT: '/events/:eventId/pages/:pageId/components/:componentId',
  GET_EVENT_PAGE_COMPONENTS: '/events/:eventId/pages/:pageId/content',
  GET_EVENT_PAGE_COMPONENT: '/events/:eventId/pages/:pageId/components/:componentId/content',
  DISCOVER_EVENTS: '/events/discover?limit=:limit&skip=:skip',
  DISCOVER_GROUPS: '/groups/discover?limit=:limit&skip=:skip',
  DISCOVER_HUBS: '/hubs/discover?limit=:limit&skip=:skip',
  DISCOVER_VOLUNTEER_OPPORTUNITIES: '/gigs/discover?limit=:limit&skip=:skip',
  CREATE_HUB: '/hubs',
  DELETE_HUB: '/hubs/:hubId',
  VALIDATE_HUB_HANDLE: '/hubs/validate/:handle',
  CHANGE_HUB_HANDLE: '/hubs/:hubId/handle',
  UPDATE_HUB: '/hubs/:hubId',
  GET_HUBS: '/hubs',
  GET_HUB: '/hubs/:handle',
  GET_HUB_BY_ID: '/hubs/by/id/:id',
  GET_HUB_VOLUNTEER_OPPORTUNITIES: '/hubs/:hubId/gigs',
  GET_HUB_SINGLE_MEMBER: '/hubs/:id/members/:userId',
  GET_HUB_ROLES: '/hubs/:id/roles',
  GET_HUB_MEMBERS: '/hubs/:id/members',
  GET_HUB_INVITES: '/invites/for/hub/:id/invites',
  GET_APPROVED_HUB_GROUPS: '/hubs/:hubId/groups?hub.group_status.code=approved',
  GET_HUB_GROUPS: '/hubs/:hubId/groups',
  APPLY_TO_HUB: '/groups/:groupId/associate/:hubId',
  APPROVE_HUB_GROUP: '/hubs/:hubId/groups/:groupId/approve',
  REJECT_HUB_GROUP: '/hubs/:hubId/groups/:groupId/reject',
  PURCAHSE_HUB_SEATS: '/billing/subscriptions/for_hub/:hub_id/:subscription_code/seats/allocate',
  GET_HUB_SUBSCRIPTION_SUMMARY: '/billing/subscriptions/for_hub/:hubId',
  UPDATE_HUB_SUBSCRIPTION: '/billing/subscriptions/for_hub/:hub_id/:subscription_code',
  GET_AVAILABLE_SUBSCRIPTIONS: '/billing/subscriptions/available_subscriptions/:type',
  GET_UNREAD_QTY: '/users/current/notifications/count/unread',
  CREATE_FORM: '/groups/:groupId/forms',
  GET_FORM_QUESTIONS: '/groups/:groupId/forms/:formId/questions',
  UPDATE_FORM: '/groups/:groupId/forms/:formId',
  UPDATE_FORM_QUESTION: '/groups/:groupId/forms/:formId/questions/:questionId',
  CREATE_FORM_QUESTION: '/groups/:groupId/forms/:formId/questions',
  DELETE_FORM_QUESTION: '/groups/:groupId/forms/:formId/questions/:questionId',
  DELETE_FORM: '/groups/:groupId/forms/:formId',
  GET_GROUP_STORE_ITEMS: '/groups/:groupId/store',
  CREATE_GROUP_STORE_ITEM: '/groups/:groupId/store',
  UPDATE_GROUP_STORE_ITEM: '/groups/:groupId/store/:storeItemId',
  DELETE_GROUP_STORE_ITEM: '/groups/:groupId/store/:storeItemId',
  CREATE_GROUP_LOCATION: '/groups/:groupId/locations',
  DELETE_GROUP_LOCATION: '/groups/:groupId/locations/:locationId',
  CREATE_GIG_LOCATION: '/gigs/:gigId/locations',
  DELETE_GIG_LOCATION: '/gigs/:gigId/locations/:locationId',
  GET_GROUP_DISCOUNTS: 'groups/:groupId/discounts',
  CREATE_GROUP_DISCOUNT: '/groups/:groupId/discounts',
  UPDATE_GROUP_DISCOUNT: '/groups/:groupId/discounts/:discountId',
  DELETE_GROUP_DISCOUNT: '/groups/:groupId/discounts/:discountId/disable',
  ACTIVATE_GROUP_DISCOUNT: '/groups/:groupId/discounts/:discountId/activate',
  GET_GROUP_PAGE_COMPONENT_DETAILS:
    '/groups/:groupId/pages/:pageId/components/:componentId/objects',
  CREATE_GROUP_MEDIA_ALBUM: '/groups/:groupId/albums',
  DELETE_GROUP_MEDIA_ALBUM: '/groups/:groupId/albums/:albumId',
  UPDATE_GROUP_MEDIA_ALBUM: '/groups/:groupId/albums/:albumId',
  GET_GROUP_MEDIA_ALBUMS: '/groups/:groupId/albums',
  GET_GIG_MEDIA_ALBUMS: '/gigs/:gigId/albums',
  CREATE_GIG_MEDIA_ALBUM: '/gigs/:gigId/albums',
  DELETE_GIG_MEDIA_ALBUM: '/gigs/:gigId/albums/:albumId',
  CREATE_GROUP_GIG: '/groups/:groupId/gigs',
  CREATE_HUB_GIG: '/hubs/:hubId/gigs',
  GET_GROUP_GIGS: '/groups/:groupId/gigs',
  GET_GROUP_LOCATIONS: '/groups/:groupId/locations',
  CREATE_EVENT_MEDIA_ALBUM: '/events/:eventId/albums',
  DELETE_EVENT_MEDIA_ALBUM: '/events/:eventId/albums/:albumId',
  UPDATE_EVENT_MEDIA_ALBUM: '/events/:eventId/albums/:albumId',
  GET_EVENT_MEDIA_ALBUMS: '/events/:eventId/albums',
  GET_GIG_BY_HANDLE: '/gigs/:handleOrId',
  UPDATE_GIG_SHIFT: '/gigs/:id/shifts/:shift_id',
  CREATE_EVENT_TEAM: '/events/:eventId/teams',
  GET_EVENT_TEAMS: '/events/:eventId/teams',
  GET_EVENT_TEAM: '/events/:eventId/teams/:teamHandleOrId',
  GET_EVENT_INDIVIDUALS: '/events/:eventId/individuals',
  GET_EVENT_INDIVIDUAL: '/events/:eventId/individuals/:userHandleOrId',
  GET_EVENT_INDIVIDUALS_EXPORT: '/events/:eventId/fundraise_individuals_export',
  GET_GROUP_INDIVIDUALS_EXPORT: '/groups/:groupId/fundraise_individuals_export',
  GET_GROUP_VOLUNTEERS_EXPORT: '/groups/:groupId/volunteers_export',
  GET_EVENT_ATTENDEES_EXPORT: '/events/:eventId/attendees_export',
  GET_EVENT_INDIVIDUAL_DONATIONS: '/events/:eventId/individuals/:userId/donations',
  CHANGE_SUSPEND_CONTENT_CREATOR: '/events/:eventId/individual/:userId/change_suspend_status',
  GET_GROUP_CAMPAIGNS: '/groups/:groupId/campaigns',
  GET_CAMPAIGNS_IS_PREFIX_USED: '/groups/:groupId/campaigns/is_prefix_used/:prefix',
  GET_CAMPAIGNS_HAS_BEEN_USED: '/groups/:groupId/campaigns/:campaignId/has_been_used',
  CREATE_GROUP_CAMPAIGN: '/groups/:groupId/campaigns',
  CLOSE_GROUP_CAMPAIGN: '/groups/:groupId/campaigns/:campaignId/close',
  UPDATE_GROUP_CAMPAIGN: '/groups/:groupId/campaigns/:campaignId',
  GET_INTEGRATIONS: '/groups/integrations',
  GET_GROUP_INTEGRATIONS: '/groups/:groupId/integrations/existing',
  REGISTER_GROUP_TO_TRAY: '/groups/:groupId/integrations/register',
  REGISTER_GROUP_SOLUTION: '/groups/:groupId/integrations/register/:solutionId',
  EDIT_GROUP_SOLUTION: '/groups/:groupId/integrations/edit/:solutionId',
  REMOVE_GROUP_SOLUTION: '/groups/:groupId/integrations/remove/:solutionId',
  GROUP_SOLUTION_HOOK: '/groups/:id/integrations/hooks/:solutionId',
  GET_EVENT_COMPONENT_COUNT: '/events/:eventId/pages/components_profile',
  CREATE_EVENT_INDIVIDUAL: '/events/:eventId/individuals',
  UPDATE_EVENT_TEAM: '/events/:eventId/teams/:teamId',
  GET_EVENT_GIGS: '/events/:eventId/gigs',
  CREATE_EVENT_GIG: '/events/:eventId/gigs',
  CREATE_EVENT_LOCATION: '/events/:eventId/locations',
  DELETE_EVENT_LOCATION: '/events/:eventId/locations/:locationId',
  GET_EVENT_LOCATIONS: '/events/:eventId/locations',
  CREATE_USER_MEDIA_ALBUM: '/users/:userId/albums',
  DELETE_USER_MEDIA_ALBUM: '/users/:userId/albums/:albumId',
  UPDATE_USER_MEDIA_ALBUM: '/users/:userId/albums/:albumId',
  GET_USER_MEDIA_ALBUMS: '/users/:userId/albums',
  GET_GROUP_PERMISSIONS: '/groups/meta/permissions',
  GET_EVENT_PERMISSIONS: '/events/meta/permissions',
  CREATE_GROUP_ROLE: '/groups/:groupId/roles',
  DELETE_GROUP_ROLE: '/groups/:groupId/roles/:id',
  VOLUNTEER_FOR_GROUP: '/groups/:groupId/applications',
  VOLUNTEER_FOR_GIG: '/gigs/:gigId/applications',
  GET_USER_APPLICATION_BY_GROUP: '/users/current/applications/:groupId',
  POST_USER_CANCEL_APPLICATION: '/users/current/applications/:application_id/cancel',
  GET_GROUP_FORM: '/groups/:groupId/forms/:form_id',
  GET_SKILLS: '/skills',
  CREATE_SKILL: '/skills',
  UPDATE_SKILL: '/skills/:id',
  DELETE_SKILL: '/skills/:id',
  GET_CAUSES: '/causes',
  CREATE_CAUSE: '/causes',
  DELETE_CAUSE: '/causes/:id',
  UPDATE_CAUSE: '/causes/:id',
  GET_GROUP_APPLICATIONS: '/groups/:groupId/applications',
  GET_GROUP_VOLUNTEERS: '/groups/:groupId/volunteers',
  GET_GROUP_CAMPAIGN: '/groups/:groupId/campaigns/:id',
  GET_EVENT_CAMPAIGN: '/events/:groupId/campaigns/:id',
  CREATE_GROUP_DONATION_INTENT: '/groups/:groupId/donations',
  GET_GROUP_DONATION_SUMMARY: '/groups/:groupId/donation_summary',
  CREATE_GROUP_DONATION_SUBSCRIPTION_INTENT: '/groups/:groupId/subscription_amounts',
  SUBMIT_GROUP_SUBSCRIPTION: '/groups/:groupId/subscriptions',
  CREATE_EVENT_DONATION_INTENT: '/events/:eventId/donations',
  GET_EVENT_DONATION_SUMMARY: '/events/:eventId/donation_summary',
  CREATE_EVENT_DONATION_SUBSCRIPTION_INTENT: '/events/:eventId/subscription_amounts',
  SUBMIT_EVENT_SUBSCRIPTION: '/events/:eventId/subscriptions',
  GET_GROUP_DONATIONS: '/groups/:groupId/donations',
  REFUND_DONATION: '/groups/:groupId/donations/:donationId/refund',
  REFUND_EVENT_DONATION: '/events/:eventId/donations/:donationId/refund',
  GET_GROUP_SUBSCRIPTIONS: '/groups/:groupId/subscriptions',
  GET_GROUP_DONORS: '/groups/:groupId/donors',
  GET_GROUP_DONORS_EXPORT: '/groups/:groupId/donors_export',
  CREATE_GIG_PAGE: '/gigs/:gigId/pages',
  GET_GIG_PAGES: '/gigs/:gigId/pages',
  GET_GIG_PAGE_COMPONENTS: '/gigs/:gigId/pages/:pageId/content',
  GET_GIG_LOCATIONS: '/gigs/:gigId/locations',
  CREATE_GIG_PAGE_COMPONENT: '/gigs/:gigId/pages/:pageId/components',
  DELETE_GIG_PAGE_COMPONENT: '/gigs/:gigId/pages/:pageId/components/:componentId',
  UPDATE_GIG_PAGE_COMPONENT: '/gigs/:gigId/pages/:pageId/components/:componentId',
  CREATE_SHIFT: '/gigs/:gigId/shifts',
  GET_SHIFTS: '/gigs/:gigId/shifts',
  REMOVE_SHIFT: '/gigs/:gigId/shifts/:shiftId',
  CHANGE_SHIFT_ASSIGNMENTS: '/gigs/:gigId/users/:userId/change_shift_assignments',
  APPROVE_GROUP_APPLICANT: '/groups/:groupId/applications/:id/approve',
  REJECT_GROUP_APPLICANT: '/groups/:groupId/applications/:id/reject',
  ASSIGN_USER_TO_SHIFT: '/gigs/:gigId/shifts/:shiftId/users',
  REMOVE_USER_FROM_SHIFT: '/gigs/:gigId/shifts/:shiftId/users/:userId',
  GET_GROUP_MEMBERS: '/groups/:groupId/members',
  GET_GROUP_MEMBERS_EXPORT: '/groups/:groupId/members_export',
  ASSIGN_GROUP_ROLE_TO_USER: '/groups/:groupId/roles/:roleId/users/:userId',
  CALCULATE_CART: '/groups/:groupId/shopping_cart',
  CALCULATE_EVENT_CART: '/events/:eventId/shopping_cart',
  CALCULATE_HUB_CART: '/hubs/:hubId/shopping_cart',
  SET_GROUP_SIGNATURE: '/groups/:groupId/signature',
  GET_GROUP_SIGNATURE: '/groups/:groupId/signature',
  GROUP_PURCHASE: '/groups/:groupId/purchases',
  REFUND_GROUP_STORE_PURCHASE: '/groups/:groupId/purchases/:transaction_id/refund',
  REFUND_EVENT_STORE_PURCHASE: '/events/:eventId/purchases/:transaction_id/refund',
  UPDATE_TRANSACTION_PAYMENT_STATUS: '/groups/:groupId/purchases/:transaction_id/updatepayment',
  GET_STORE_FORMS: '/groups/:groupId/store_front',
  GET_GROUP_GIG_PAYMENTS: '/groups/:groupId/payments',
  GET_EVENT_GIG_PAYMENTS: '/events/:eventId/payments',
  GET_USER_PURCHASES: '/users/current/purchases',
  GET_USER_PURCHASES_EXPORT: '/users/current/purchases/export',
  GET_USER_TICKETS: '/users/current/tickets',
  GET_USER_SPONSORSHIPS: '/users/current/sponsorships',
  SEARCH_GROUPS: '/groups/search',
  SEARCH_GIGS: '/gigs/search',
  SEARCH_EVENTS: '/events/search',
  GET_USER_DONATIONS: '/users/current/donations',
  GET_USER_DONATIONS_EXPORT: '/users/current/donations/export',
  GET_USER_APPLICATIONS: '/users/current/applications',
  GET_USER_GIGS: '/users/current/gigs',
  GET_USER_GIGS_FOR_LOGGING_HOURS: '/volunteering/for/user/current/gigs_for_logging_hours',
  RESET_PASSWORD_EMAIL: '/auth/password',
  RESET_PASSWORD: '/auth/password',
  GET_USER_ATTENDING: '/users/current/attending_events',
  ADD_VOLUNTEER: '/groups/:groupId/volunteers',
  DOWNLOAD_DONATION_RECEIPT: '/users/current/donations/:donationId/receipt',
  DOWNLOAD_HUB_SEAT_RECEIPT:
    '/billing/for_hub/:hub_id/:subscription_code/history/:invoice_id/download',
  GET_GROUP_CONVERSATION_MEMBERS: '/groups/:groupId/managers',
  GET_EVENT_CONVERSATION_MEMBERS: '/events/:eventId/managers',
  GET_GROUP_APPLICATIONS_BY_USER_HANDLE: '/groups/:groupId/volunteers/:userId/applications',
  GET_HUB_APPLICATIONS_BY_USER: '/hubs/:hubId/volunteers/:userId/applications',
  APPROVE_HUB_APPLICATION: '/hubs/:hubId/applications/:applicationId/approve',
  REJECT_HUB_APPLICATION: '/hubs/:hubId/applications/:applicationId/reject',
  EVENT_PURCHASE: '/events/:eventId/purchases',
  GET_EVENT_MEMBERS: '/events/:eventId/members',
  GET_EVENT_MEMBERS_EXPORT: '/events/:eventId/members_export',
  ASSIGN_EVENT_ROLE_TO_USER: '/events/:eventId/roles/:roleId/users/:userId',
  GET_EVENT_ROLES: '/events/:eventId/roles',
  UPDATE_EVENT_ROLE: '/events/:eventId/roles/:id',
  CREATE_EVENT_ROLE: '/events/:eventId/roles',
  DELETE_EVENT_ROLE: '/events/:eventId/roles/:id',
  VERIFY_EMAIL: '/users/current/verify',
  CHECK_DUPLICATE_EMAIL: '/auth/check_email_exists/:email',
  GET_EVENT_ATTENDEES: '/events/:eventId/attendees',
  GET_EVENT_DONATIONS: '/events/:eventId/donations',
  DOWNLOAD_TICKET: '/users/current/tickets/:ticketId/download',
  CHECK_IN_EVENT_TICKET: '/events/:eventId/attendees/:attendeeId/tickets/:ticketId/check_in',
  GET_TEAM_MEMBERS: '/events/:eventId/teams/:teamId/individuals',
  GET_TEAM_DONATIONS: '/events/:eventId/teams/:teamId/donations',
  JOIN_EVENT_TEAM: '/events/:eventId/teams/:teamId/individuals',
  UPDATE_INDIVIDUAL: '/events/:event/individuals/current',
  GET_USER_GIG_APPLICATIONS: '/users/current/applications/:groupId/gig_applications',
  GET_USER_GROUP_APPLICATION: '/users/current/applications/:groupId/group_applications',
  DOWNLOAD_GROUP_DONATION_RECEIPT: '/groups/:groupId/donations/:transaction_id/receipt',
  DOWNLOAD_GROUP_PURCHASE_RECEIPT: '/groups/:groupId/purchases/:transaction_id/download',
  DOWNLOAD_USER_PURCHASE_RECEIPT: '/users/current/purchases/:transaction_id/download',
  DOWNLOAD_USER_AUCTION_RECEIPT: '/users/current/auction_items/:transaction_id/download',
  UPDATE_THANK_YOU_MESSAGE: '/groups/:groupId/donations/:transaction_id/confirmation_message',
  UPDATE_THANK_YOU_MESSAGE_BULK: '/groups/:groupId/donations/confirmation_message',
  SEND_CONFIRMATION_EMAIL: '/groups/:groupId/donations/:transaction_id/resend_confirmation',
  SEND_CONFIRMATION_EMAIL_BULK: '/groups/:groupId/donations/resend_confirmation',
  REGISTER_ITEM_GROUP: '/groups/:groupId/virtual_purchases/:id/confirm',
  REGISTER_ITEM_EVENT: '/events/:eventId/virtual_purchases/:id/confirm',
  GET_PURCHASE_ITEMS: '/groups/:groupId/purchases/:transaction_id/items',
  GET_PURCHASE_EVENT_ITEMS: '/events/:eventId/purchases/:transaction_id/items',
  GET_EVENT_FUNDRAISING_REGISTRATION_TICKET: '/events/:eventId/registration_item',
  GET_EVENT_FUNDRAISING_REGISTRATION_TICKETS: '/events/:eventId/registration_items',
  VERIFY_EVENT_FUNDRAISING_PURCHASE: '/events/:eventId/purchases/items/:store_item_id/verify',
  EXPORT_PURCHASES: '/groups/:groupId/purchases_export',
  EXPORT_PURCHASES_EVENT: '/events/:eventId/purchases_export',
  SEND_TAX_RECEIPT: '/groups/:groupId/donations/:donationId/resend_tax_receipt',
  SEND_TAX_RECEIPT_BULK: '/groups/:groupId/donations/resend_tax_receipt',
  GET_IS_USED_TAX_RECEIPT_NUMBER:
    '/groups/:groupId/donations/campaign/:campaignId/is_used_tax_receipt_number/:sequence_number',
  GROUP_GENERATE_TAX_RECEIPT: '/groups/:groupId/donations/:donationId/generate_tax_receipt',
  GROUP_EDIT_TAX_RECEIPT: '/groups/:groupId/donations/:donationId/edit_tax_receipt',
  EVENT_GENERATE_TAX_RECEIPT: '/events/:eventId/donations/:donationId/generate_tax_receipt',
  EVENT_EDIT_TAX_RECEIPT: '/events/:eventId/donations/:donationId/edit_tax_receipt',
  REGENERATE_TAX_RECEIPT: '/groups/:groupId/donations/:donationId/regenerate_tax_receipt',
  REGENERATE_TAX_RECEIPT_BULK: '/groups/:groupId/donations/regenerate_tax_receipt',
  DOWNLOAD_EVENT_DONATION_RECEIPT: '/events/:eventId/donations/:transaction_id/receipt',
  SEND_EVENT_TAX_RECEIPT: '/events/:eventId/donations/:donationId/resend_tax_receipt',
  REGENERATE_EVENT_TAX_RECEIPT: '/events/:eventId/donations/:donationId/regenerate_tax_receipt',
  REGENERATE_EVENT_TAX_RECEIPT_BULK: '/events/:eventId/donations/regenerate_tax_receipt',
  DOWNLOAD_EVENT_PURCHASE_RECEIPT: '/events/:eventId/purchases/:transaction_id/download',
  GET_USER_LOCATIONS: '/users/current/locations',
  UPDATE_USER_LOCATION: '/users/current/locations/:location_id',
  CREATE_USER_LOCATION: '/users/current/locations',
  DELETE_USER_LOCATION: '/users/current/locations/:location_id',
  CREATE_GROUP_AUCTION_ITEM: '/groups/:groupId/auction_items',
  UPDATE_GROUP_AUCTION_ITEM: '/groups/:groupId/auction_items/:id',
  GET_DONATIONS_EXPORT: '/groups/:groupId/donations_export',
  GET_DONATIONS_EXPORT_EVENT: '/events/:eventId/donations_export',
  GET_DONATION_NEXT_DEFAULT_TAX_RECEIPT_NUMBER:
    'groups/:groupId/donations/campaign/:campaignId/next_default_tax_receipt',
  GET_PLEDGES_EXPORT: '/groups/:groupId/pledges_export',
  GET_ISSUED_RECEIPTS_EXPORT: '/groups/:groupId/issued_tax_receipts_export/:fiscalYear',
  GET_SKIPPED_RECEIPTS_EXPORT: '/groups/:groupId/skipped_tax_receipts_export/:fiscalYear',
  GET_FISCAL_YEAR_LIST: '/groups/:groupId/fiscal_years',
  GET_USER_SUBSCRIPTIONS: '/users/current/subscriptions',
  CANCEL_USER_SUBSCRIPTION: '/users/current/subscriptions/:id/cancel',
  ADD_DONOR: '/groups/:groupId/donors',
  GROUP_BID_ITEM: '/groups/:groupId/auction_items/:id/bids',
  GET_USER_AUCTION_ITEMS: '/users/current/auction_items',
  GET_USER_AUCTION_ITEMS_EXPORT: '/users/current/auction_items/export',
  GET_CAMPAIGN: '/campaigns/:id',
  GET_AUCTION_SUMMARY: '/users/current/auction_items/:id/payment_summary',
  ARCHIVE_APPLICATION: '/users/current/archive_application/:applicationId',
  CREATE_AUCTION_PAYMENT_INTENT: '/users/current/auction_items/:id/payment',
  GET_GROUP_RECEIPT_NUMBER: '/groups/:groupId/campaigns/:campaign_id/receipt_number',
  GET_GROUP_RECEIPT_NUMBER_INFO_NEXT_AVAILABLE:
    '/groups/:groupId/campaigns/:campaign_id/receipt_number_info_next_available/',
  GET_GROUP_RECEIPT_NUMBER_FORMATTED:
    '/groups/:groupId/campaigns/:campaign_id/receipt_number_formated',
  GET_GROUP_RECEIPT_NUMBER_PREVIEW: '/groups/:groupId/receipt_number_preview',
  UPDATE_USER_EMAIL: '/users/current/email',
  CREATE_BUY_NOW_INTENT: '/groups/:groupId/auction_items/:id/buy_now',
  GET_BUY_NOW_SUMMARY: '/groups/:groupId/auction_items/:id/buy_now_summary',
  GET_AUCTIONS_EXPORT: '/groups/:groupId/auction_payments_export',
  GET_GROUP_AUCTION_TRANSACTIONS: '/groups/:groupId/auction_payments',
  SEND_GROUP_AUCTION_RECEIPT: '/groups/:groupId/auction_payments/:transactionId/send_receipt',
  SET_AUCTION_WINNER: '/groups/:groupId/auction_items/:itemId/winner/:userId',
  BID_SUMMARY: '/groups/:groupId/auction_items/:item_id/bid_summary',
  GET_GROUP_SHIFT_USERS: '/groups/:groupId/shift_users',
  GET_EVENT_SHIFT_USERS: '/events/:eventId/shift_users',
  VERIFY_SHIFT_HOURS: '/gigs/:gigId/shifts/:shift_id/users/:userId/approve',
  BULK_VERIFY_SHIFT_HOURS: '/gigs/:gigId/approve_shift_hours',
  UPDATE_SHIFT_USER_HOURS: '/gigs/:gigId/shifts/:shift_id/users/:user_id/hours',
  ADMIN_REPORT_SHIFT_HOURS: '/gigs/:gigId/user/:user_id/report_hours',
  CREATE_USER_ENTERED_SHIFT: '/gigs/:gigId/user/:user_id/create_user_shift',
  REFUND_AUCTION_ITEM: '/groups/:groupId/auction_payments/:item_id/refund',
  GET_GIGS_EXPORT: '/users/current/volunteer_hours/download',
  ARCHIVE_GROUP: '/groups/:groupId/archive',
  GROUP_PLEDGE: '/groups/:groupId/pledges',
  GET_EVENT_STORE_ITEMS: '/events/:eventId/store',
  CREATE_EVENT_STORE_ITEM: '/events/:eventId/store',
  UPDATE_EVENT_STORE_ITEM: '/events/:eventId/store/:storeItemId',
  DELETE_EVENT_STORE_ITEM: '/events/:eventId/store/:storeItemId',
  GET_EVENT_STORE_FORMS: '/events/:eventId/store_front',
  GET_HUB_STORE_FORMS: '/hubs/:hubId/store_front',
  GROUP_EXPORT_STORE_DAILY: '/groups/:groupId/purchases_daily_summary_export',
  EVENT_EXPORT_STORE_DAILY: '/events/:eventId/purchases_daily_summary_export',
  GET_EVENT_DISCOUNTS: 'events/:eventId/discounts',
  CREATE_EVENT_DISCOUNT: '/events/:eventId/discounts',
  UPDATE_EVENT_DISCOUNT: '/events/:eventId/discounts/:discountId',
  DELETE_EVENT_DISCOUNT: '/events/:eventId/discounts/:discountId/disable',
  ACTIVATE_EVENT_DISCOUNT: '/events/:eventId/discounts/:discountId/activate',
  GET_DAILY_AUCTION_WINNERS: '/groups/:groupId/auction_winners_export',
  GET_USER_BY_HANDLE: '/users/:handleOrId',
  GET_USER_BY_EMAIL: '/users/by_email/:email',
  RESEND_EMAIL_VERIFICATION: '/users/current/resend_email_verification',
  GET_GROUP_AUCTION_ITEMS_ADMIN: '/groups/:groupId/auction_items_admin',
  GET_GROUP_CLASSIFICATIONS: '/group_classifications',
  GET_PUBLIC_DONATIONS_GROUP: '/groups/:id/donations_public?limit=:limit&skip=:skip',
  GET_PUBLIC_DONATIONS_EVENT: '/events/:id/donations_public?limit=:limit&skip=:skip',
  GET_EVENT_AUCTION_ITEMS: '/events/:eventId/auction_items',
  GET_EVENT_AUCTION_ITEMS_ADMIN: '/events/:eventId/auction_items_admin',
  UPDATE_EVENT_AUCTION_ITEM: '/events/:eventId/auction_items/:id',
  UPDATE_EVENT_AUCTION_ITEM_ORDER: '/events/:eventId/auction_items/:id/change_order',
  CREATE_EVENT_AUCTION_ITEM: '/events/:eventId/auction_items',
  CHARGE_EVENT_AUCTION_ITEM_WINNER: '/events/:eventId/auction_items/:itemId/charge_winner',
  SET_EVENT_AUCTION_WINNER: '/events/:eventId/auction_items/:itemId/winner/:userId',
  EVENT_BID_ITEM: '/events/:eventId/auction_items/:id/bids',
  GET_EVENT_BUY_NOW_SUMMARY: '/events/:eventId/auction_items/:id/buy_now_summary',
  CREATE_EVENT_BUY_NOW_INTENT: '/events/:eventId/auction_items/:id/buy_now',
  GET_EVENT_AUCTION_TRANSACTIONS: '/events/:eventId/auction_payments',
  SEND_EVENT_AUCTION_RECEIPT: '/events/:eventId/auction_payments/:transactionId/send_receipt',
  REFUND_EVENT_AUCTION_ITEM: '/events/:eventId/auction_payments/:item_id/refund',
  GET_EVENT_AUCTIONS_EXPORT: '/events/:eventId/auction_payments_export',
  GET_EVENT_DAILY_AUCTION_WINNERS: '/events/:eventId/auction_winners_export',
  EVENT_BID_SUMMARY: '/events/:eventId/auction_items/:item_id/bid_summary',
  GET_COMMUNITY_IMPACT_SUMMARY: '/groups/:groupId/community_impact_summary',
  GET_RECEIPT_NUMBER: '/groups/:groupId/campaigns/:campaign_id/receipt_number',
  UPDATE_RECEIPT_NUMBER: '/groups/:groupId/campaigns/:campaign_id/receipt_number',
  GET_PAYMENT_METHODS: '/users/current/payment_methods',
  DELETE_PAYMENT_METHOD: 'users/current/payment_methods/:id',
  SET_PAYMENT_METHOD_AS_DEFAULT: 'users/current/payment_methods/:id/default',
  GET_TWITCH_AUTH_LINK: 'users/current/twitch_link',
  DISSOCIATE_TWITCH: 'users/current/twitch_unlink',
  GET_STRAVA_AUTH_LINK: 'users/current/strava_link',
  DISSOCIATE_STRAVA: 'users/current/strava_unlink',
  GET_STREAM_WIDGETS: '/events/:eventId/individuals/:individualId/widgets',
  GET_WIDGET_DETAILS: '/events/widgets/:widgetId',
  UPDATE_WIDGET: '/events/:eventId/individuals/:userId/widgets/:widgetType',
  GET_GIG_CATEGORIES: '/gig_categories',
  GET_COMPANY_GIG_VOLUNTEERS: '/volunteering/for/gig/:gigId/volunteers',
  GET_GIG_VOLUNTEERS: '/gigs/:gigId/volunteers',
  GET_GIG_VOLUNTEERS_VOLUNTEERING: '/volunteering/for/gig/:gig_id/volunteers',
  GET_GIG_VOLUNTEER: '/volunteering/for/gig/:gigId/volunteers/:userId',
  GET_GIG_VOLUNTEERS_ALL: '/volunteering/for/gig/:gigId/volunteers_all',
  GET_GIG_VOLUNTEERS_EXPORT: '/gigs/:gigId/volunteers/export',
  CREATE_FUNDRAISER: '/groups/groupId/fundraisers',
  GET_FUNDRAISERS: '/groups/:groupId/fundraisers',
  GET_GROUPS_OBOF_ALLOWED: '/groups?allow_obo_fundraisers=true',
  CREATE_OBO_EVENT: '/groups/:groupId/fundraisers',
  VERIFY_GROUP_HANDLE: '/groups/validate/:handle',
  VERIFY_EVENT_HANDLE: '/events/validate/:handle',
  TWITCH_STATUS: '/twitch/status',
  GET_GROUP_SPONSORSSHIP_SETTINGS: '/groups/:groupId/sponsors/settings_for_group',
  UPDATE_GROUP_SPONSORSSHIP_SETTINGS: '/groups/:groupId/sponsors/settings_for_group',
  GET_SPONSORS: '/groups/:groupId/sponsors',
  GET_EVENT_SPONSORS: '/events/:eventId/sponsors/find_for_event',
  GET_EVENT_DONATIONS_STATS: '/events/:event_id/donation_stats',
  GET_EVENT_STORE_PURCHASES_STATS: '/events/:event_id/store_purchase_stats',
  GET_EVENT_STORE_STATS: '/events/:event_id/store_stats',
  GET_EVENT_AUCTION_STATS: '/events/:event_id/auction_stats',
  GET_EVENT_AUCTION_PURCHASES_STATS: '/events/:event_id/auction_purchase_stats',
  GET_GROUP_DONATIONS_STATS: '/groups/:group_id/donation_stats',
  GET_GROUP_STORE_PURCHASES_STATS: '/groups/:group_id/store_purchase_stats',
  GET_GROUP_STORE_STATS: '/groups/:group_id/store_stats',
  GET_GROUP_AUCTION_STATS: '/groups/:group_id/auction_stats',
  GET_GROUP_AUCTION_PURCHASES_STATS: '/groups/:group_id/auction_purchase_stats',
  UPDATE_EVENT_SPONSORSHIP_ITEM: '/events/:eventId/sponsors/update_item/:transactionItemId',
  SEND_AUCTION_CONFIRMATION_EMAIL:
    '/groups/:groupId/auction_payment_reminders/:item_id/confirmation',
  SEND_GROUP_AUCTION_CONFIRMATION_EMAIL:
    '/groups/:groupId/auction_payment_reminders/:item_id/confirmation',
  SEND_EVENT_AUCTION_CONFIRMATION_EMAIL:
    '/events/:eventId/auction_payment_reminders/:item_id/confirmation',
  UPDATE_CAMPAIGN_RAISED_AMOUNT: '/groups/:groupId/campaigns/:campaign_id/raised_amount',
  UPDATE_EVENT_CAMPAIGN_RAISED_AMOUNT: '/events/:eventId/campaign/raised_amount',
  FUNDRAISE_CHECKOUT: '/events/:eventId/register_for_fundraiser',
  CHECK_FUNDRAISE_STATUS: '/events/:eventId/check_fundraiser_user_register_status/:email',
  CREATE_CUSTOM_FIELD: '/groups/:groupId/custom_fields',
  CREATE_COMMUNICATION_RECORD: '/groups/:groupId/members/:memberId/communication_records',
  DELETE_COMMUNICATION_RECORD: '/groups/:groupId/members/:memberId/communication_records/:recordId',
  UPDATE_COMMUICATION_RECORD: '/groups/:groupId/members/:memberId/communication_records/:recordId',
  CREATE_ACTIVITY_LOG: '/groups/:groupId/members/:memberId/activities',
  GET_ACTIVITY_LOG: '/groups/:groupId/members/:memberId/activities',
  DELETE_ACTIVITY_LOG: '/groups/:groupId/members/:memberId/activities/:activityId',
  GET_SOCIALS: '/groups/:groupId/members/:memberId/accounts',
  GET_MOMENTS: '/groups/:groupId/members/:memberId/moments',
  UPDATE_SOCIAL: '/groups/:groupId/members/:memberId/accounts/:socialId',
  CREATE_MOMENT: '/groups/:groupId/members/:memberId/moments',
  UPDATE_MOMENT: '/groups/:groupId/members/:memberId/moments/:momentId',
  GET_CONTACT_DONATIONS: '/groups/:groupId/donors/:contactId/donations',
  GET_GROUP_INVITES: '/invites/for/group/:groupId/invites',
  INVITE_TO_GROUP: '/invites/for/group/:groupId/invites',
  CANCEL_INVITE_TO_GROUP: '/invites/for/group/:groupId/invites/:inviteId/cancel',
  ACCEPT_INVITE_TO_GROUP: '/invites/for/group/:groupId/invites/:inviteId/accept',
  REJECT_INVITE_TO_GROUP: '/invites/for/group/:groupId/invites/:inviteId/reject',
  SEND_GROUP_INVITE_REMINDER: '/invites/for/groups/:groupId/invites/:inviteId/reminder',
  GET_EVENT_INVITES: '/invites/for/event/:eventId/invites',
  INVITE_TO_EVENT: '/invites/for/event/:eventId/invites',
  CANCEL_INVITE_TO_EVENT: '/invites/for/event/:eventId/invites/:inviteId/cancel',
  ACCEPT_INVITE_TO_EVENT: '/invites/for/event/:eventId/invites/:inviteId/accept',
  REJECT_INVITE_TO_EVENT: '/invites/for/event/:eventId/invites/:inviteId/reject',
  SEND_EVENT_INVITE_REMINDER: '/invites/for/event/:eventId/invites/:inviteId/reminder',
  FIND_INVITE_FROM_EMAIL_TOKEN: '/users/invites/from_token/:inviteId/:emailToken',
  GET_USER_MYPAGE_ITEMS: '/users/current/mypages?pages_type=:type',
  GET_DONOR: '/groups/:groupId/donors/:contactId',
  GET_GROUP_PLEDGES_FOR_USER: '/groups/:groupId/donors/:donorId/pledges',
  GET_SPONSORSHIPS_FOR_USER: '/groups/:groupId/sponsors/:userId/sponsorships',
  GET_SPONSOR_INFO_FOR_USER: '/groups/:groupId/sponsors/:userId',
  GET_FUNDRAISERS_FOR_USER: '/groups/:groupId/fundraisers/:userId',
  GET_VOLUNTEER_FOR_USER: '/groups/:groupId/volunteers/:userId',
  GET_VOLUNTEER_SHIFTS_FOR_USER: '/groups/:groupId/volunteers/:userId/shifts',
  POST_SET_VOLUNTEER_FLAG: '/volunteering/for/gig/:gig_id/volunteers/:user_id/set_volunteer_flag',
  POST_SET_VOLUNTEER_FLAG_BULK: '/volunteering/for/gig/:gig_id/volunteers/set_volunteer_flag/bulk',
  GET_HUB_VOLUNTEERS: '/volunteering/for/hub/:hub_id/volunteers',
  GET_EVENT_FUNDRAISERS_FOR_USER: '/groups/:groupId/fundraisers/:userId/event_fundraisers_for_user',
  GET_SUPPORTER_INFO: '/groups/:groupId/supporters/:userId',
  GET_PURCHASES_FOR_USER: '/groups/:groupId/supporters/:userId/purchases',
  CREATE_GROUP_PLEDGE: '/groups/:groupId/donors/:userId/pledges',
  DELETE_PLEDGE: '/groups/:groupId/donors/:userId/pledges/:pledgeId',
  GET_DONOR_CLASSIFICATION_REPORT: '/groups/:groupId/donor_classification_report',
  GET_DONOR_CLASSIFICATION_REPORT_EXPORT: '/groups/:groupId/export_donor_classification_report',
  CHANGE_GROUP_STATUS: '/groups/:groupId/status',
  CHANGE_EVENT_STATUS: '/events/:eventId/status',
  CHANGE_GIG_STATUS: '/gigs/:gigId/status',
  APPLY_DONATION_TO_PLEDGE:
    '/groups/:groupId/donors/:donorId/donations/:donationId/associate_pledge/:pledgeId',
  DISSOCIATE_DONATION_PLEDGE:
    '/groups/:groupId/donors/:userId/donations/:donationId/dissociate_pledge',
  GET_GROUP_CUSTOM_FIELDS: '/groups/:groupId/custom_fields',
  GET_USER_CUSTOM_FIELDS: '/groups/:groupId/members/:userId',
  SET_USER_CUSTOM_FIELDS: 'groups/:groupId/custom_field_values/:userId',
  DELETE_USER_CUSTOM_FIELD: '/groups/:groupId/custom_field_values/:userId/clear',
  DELETE_CUSTOM_FIELD: '/groups/:groupId/custom_fields/:cfId',
  EDIT_CUSTOM_FIELD: '/groups/:groupId/custom_fields/:cfId',
  GET_EVENT_CHILD_EVENTS: '/events/:eventId/children',
  FIND_GROUP_STORE_CATEGORIES: '/groups/:groupId/store_categories',
  CREATE_GROUP_STORE_CATEGORY: '/groups/:groupId/store_categories',
  UPDATE_GROUP_STORE_CATEGORY: '/groups/:groupId/store_categories/:storeCategoryId',
  DELETE_GROUP_STORE_CATEGORY: '/groups/:groupId/store_categories/:storeCategoryId',
  FIND_EVENT_STORE_CATEGORIES: '/events/:eventId/store_categories',
  CREATE_EVENT_STORE_CATEGORY: '/events/:eventId/store_categories',
  UPDATE_EVENT_STORE_CATEGORY: '/events/:eventId/store_categories/:storeCategoryId',
  DELETE_EVENT_STORE_CATEGORY: '/events/:eventId/store_categories/:storeCategoryId',
  GET_USER_GROUPS_FOR_PERMISSION: 'users/current/groups_for_permission/:permission',
  GET_USER_FUNDRAISING_EVENTS: '/users/current/fundraising_events',
  GET_USER_FUNDRAISING_URL: 'users/fundraiser_redirect/:userHandle',
  TOGGLE_EVENT_INDIVIDUAL_FITNESS: '/events/:eventId/individuals/:user_handle/fitness',
  TOGGLE_EVENT_TEAM_FITNESS: '/events/:eventId/teams/:team_id/fitness',
  TOGGLE_EVENT_FITNESS: '/events/:eventId/fitness',
  TOGGLE_EVENT_CONTENT_CREATOR: '/events/:eventId/content_creator',
  GET_GROUP_HUB_MEMBER_GROUPS: '/groups/:groupId/hub_groups',
  REQUEST_HUB_ASSOCIATION: '/groups/:groupId/associate/:hubId',
  APPROVE_HUB_ASSOCIATION: '/groups/:groupId/hub_groups/:candidateGroupId/approve',
  REJECT_HUB_ASSOCIATION: '/groups/:groupId/hub_groups/:candidateGroupId/reject',
  UPDATE_HUB_GROUP_POSTAL_CODE_PATTERNS:
    '/groups/:groupId/hub_groups/:candidateGroupId/postal_codes',
  GET_GROUP_HUB_OWNER_GROUP: '/groups/:groupId/hub_groups/find_group_hub_owner_group',
  GET_GROUP_DELEGATIONS: '/groups/:groupId/delegations',
  GET_EVENT_GROUP_DELEGATIONS: '/events/:eventId/delegations',
  GET_GROUP_HUBS: '/groups/:groupId/hubs',
  SET_GROUP_PRIMARY_HUB: '/groups/:groupId/hubs/:hubId/actions/primary',
  ARCHIVE_CONVERSATION: '/chat/conversations/:id/archive',
  GET_GROUP_SUMMARY: '/groups/:groupId/stats?start_date=:startDate&end_date=:endDate',
  REQUEST_CHARITY_VERIFICATION: '/groups/:groupId/charity_verification/request',
  APPROVE_CHARITY_VERIFICATION: '/groups/charity_verification/:id/approve',
  DECLINE_CHARITY_VERIFICATION: '/groups/charity_verification/:id/decline',
  PENDING_CHARITY_GROUPS: '/groups/charity_verification',
  APPROVE_CHARITY_GROUP: '/groups/:groupId/charity_verification/approve',
  DECLINE_CHARITY_GROUP: '/groups/:groupId/charity_verification/decline',
  CHECK_USER_PASS_SET: '/users/check_pass_set/:userId',
  DECLINE_SHIFT_USER: '/users/current/gig_shifts/:gig_id/shift/:shift_id',
  GIG_SHIFT_DAILY_EXPORT: '/gigs/:gigId/shifts/daily/download',
  GROUP_CHANGE_DONATION_DESTINATION: '/groups/:id/donations/:transaction_id/change_destination',
  EVENT_CHANGE_DONATION_DESTINATION: '/events/:id/donations/:transaction_id/change_destination',
  GET_USER_ACTIVITY_FEED_ITEMS_SUMMERY: '/activity_feed/my_feed',
  GET_OBJECTS_ACTIVITY_FEED_ITEMS_SUMMERY: '/activity_feed/object_feed/for/:objectType/:objectId',
  GET_POST_COMMENTS: '/activity_feed/posts/:postId/comments',
  CREATE_ACTIVITY_FEED_POST: '/activity_feed/posts',
  CREATE_SHARE_FEED_POST: '/activity_feed/posts/from_object/:object_type/:object_id',
  SHARE_POST: '/activity_feed/posts/:postId/share',
  LIKE_POST: '/activity_feed/posts/:postId/like',
  UNLIKE_POST: '/activity_feed/posts/:postId/unlike',
  COMMENT_ON_POST: '/activity_feed/posts/:postId/comments',
  COMMENT_ON_POST_PARENT_COMMENT: '/activity_feed/posts/:postId/comments/:commentId',
  DELETE_POST_COMMENT: '/activity_feed/posts/:postId/comments/:commentId',
  DELETE_POST_COMMENT_CHILD_COMMENT:
    '/activity_feed/posts/:postId/comments/:commentId/child_comments/:childCommentId',
  HIDE_POST: '/activity_feed/posts/:postId/hide',
  HUB_PARTNERS: '/hubs/:id/groups',
  GET_HUB_PARTNERS: '/hubs/:id/partners/groups',
  REMOVE_HUB_MEMBERS: '/hubs/:id/members/:user_id',
  UPDATE_HUB_MEMBER_ROLE: '/hubs/:id/roles/:roleId/users/:memberId',
  GET_HUB_AUCTION_ITEMS: '/hubs/:id/auction_items',
  GET_HUB_STORE_ITEMS: '/hubs/:id/store',
  ACCEPT_INVITE_TO_HUB: '/invites/for/hub/:id/invites/:inviteId/accept',
  REJECT_INVITE_TO_HUB: '/invites/for/hub/:id/invites/:inviteId/reject',
  GET_HUB_AUCTION_ADMIN_ITEMS: '/hubs/:id/auction_items_admin',
  DELETE_HUB_AUCTION_ITEM: '/hubs/:id/auction_items/:item_id',
  CREATE_HUB_STORE_ITEM: '/hubs/:hubId/store',
  UPDATE_HUB_STORE_ITEM: '/hubs/:hubId/store/:item_id',
  CREATE_HUB_AUCTION_ITEM: '/hubs/:hubId/auction_items',
  UPDATE_HUB_AUCTION_ITEM: '/hubs/:hubId/auction_items/:item_id',
  RECONCILE_MATCHING_PROGRAM_PAYOUT:
    'donation_matching_programs/reconcile_payment/for/group/:object_id/:program_id',
  EXPORT_HUB_DONATION_MATCHING_PROGRAM:
    '/donations/donation_matching_programs/for/hub/:object_id/:program_id/export',
  EXPORT_HUB_VOLUNTEER_MATCH_SUMMARY: '/hubs/:id/payment_package/:package_id/export',
  EXPORT_HUB_DONATIONS: '/donations/for/hub/:object_id/donations_export',
  EXPORT_HUB_BULK_DONATION_RECEIPT: '/hubs/:id/donations/bulk_receipts',
  EXPORT_HUB_DONATION_RECEIPT: '/hubs/:id/donations/:transaction_id/receipt',
  CREATE_HUB_DONATION_MATCHING_PROGRAM: '/donations/donation_matching_programs/for/hub/:object_id',
  UPDATE_HUB_DONATION_MATCHING_PROGRAM:
    '/donations/donation_matching_programs/for/hub/:object_id/:program_id',
  GET_DONATION_MATCHING_PAYOUT_SUMMARY:
    '/donations/donation_matching_programs/for/hub/:object_id/:program_id/payout_group/:group_id/summary',
  GET_DONATION_MATCHING_PAYMENT_METHOD:
    '/donations/donation_matching_programs/for/hub/:object_id/:program_id/payout_group/:group_id/available_payment_methods',
  GET_DONATIONS_FOR_DONATION_MATCHING_PROGRAM:
    '/donations/donation_matching_programs/for/hub/:object_id/:program_id/donations',
  CREATE_DONATION_MATCHING_PAYOUT:
    '/donations/donation_matching_programs/for/hub/:object_id/:program_id/payout_group/:group_id/payout',
  REQUEST_DONATION_MATCH:
    '/donations/donation_matching_programs/for/hub/:object_id/:program_type/:transaction_id/request_donation_match',
  DELETE_HUB_STORE_ITEM: '/hubs/:id/store/:item_id',
  GET_HUB_PARTNER_CAUSES: '/hubs/:id/partners/groups/causes',
  GET_HUB_EVENTS: '/hubs/:id/events',
  DUPLICATE_HUB_EVENT: '/hubs/:hubId/event/duplicate',
  GET_HUB_PARTNER_EVENTS: '/hubs/:id/partners/events',
  GET_HUB_PARTNER_EVENTS_CAUSES: '/hubs/:id/partners/events/causes',
  GET_HUB_EVENTS_CAUSES: '/hubs/:id/partners/events/causes',
  HUB_INVITE_USERS: '/invites/for/hub/:id/invites',
  HUB_INVITE_GROUP: '/hubs/:id/partners/groups/invites',
  HUB_ROLES: '/hubs/:hubId/roles',
  HUB_USERS_BY_ROLE: '/hubs/:hubId/roles/:roleId/users',
  HUB_CANCEL_INVITE: '/invites/for/hub/:id/invites/:inviteId/cancel',
  HUB_INVITE_REMINDER: '/invites/for/hub/:id/invites/:inviteId/reminder',
  HUB_PURCHASE: '/hubs/:id/purchases',
  REMOVE_PARTNER_HUB: '/hubs/:id/groups/:groupId',
  GET_HUB_PAGES: '/hubs/:id/pages',
  CREATE_HUB_PAGE: '/hubs/:id/pages',
  UPDATE_HUB_PAGE: '/hubs/:id/pages/:page_id',
  UPDATE_BULK_HUB_PAGES: '/hubs/:id/pages/bulk',
  DELETE_HUB_PAGE: '/hubs/:id/pages/:page_id',
  GET_HUB_PAGE_COMPONENT: '/hubs/:id/pages/:page_id/components/:comp_id',
  GET_HUB_PARTNERS_GIG_ITEMS: '/hubs/:id/partners/groups/gigs',
  CREATE_HUB_PAGE_COMPONENT: '/hubs/:id/pages/:page_id/components/:comp_id',
  UPDATE_HUB_PAGE_COMPONENT: '/hubs/:id/pages/:page_id/components/:comp_id',
  DELETE_HUB_PAGE_COMPONENT: '/hubs/:id/pages/:page_id/components/:comp_id',
  FIND_HUB_STORE_CATEGORIES: '/hubs/:hubId/store_categories',
  GET_HUB_STORE_STATS: '/hubs/:id/store_stats',
  GET_HUB_AUCTION_STATS: '/hubs/:id/auction_stats',
  GET_HUB_DISCOUNTS: 'hubs/:hubId/discounts',
  CREATE_HUB_DISCOUNT: '/hubs/:hubId/discounts',
  UPDATE_HUB_DISCOUNT: '/hubs/:hubId/discounts/:discountId',
  DELETE_HUB_DISCOUNT: '/hubs/:hubId/discounts/:discountId/disable',
  ACTIVATE_HUB_DISCOUNT: '/hubs/:hubId/discounts/:discountId/activate',
  ISSUE_CREDITS_TO_USER: '/hubs/:id/issue_credits_to/:user_id',
  GET_VOLUNTEER_MATCH_LIST: '/hubs/:id/volunteer_match_list/:program_id',
  GET_VOLUNTEER_MATCH_LIST_ALL: '/hubs/:id/volunteer_match_list/all',
  GET_VOLUNTEER_MATCH_STATS_FOR_HUB: '/hubs/:id/volunteer_match_stats',
  GET_VOLUNTEER_MATCH_STATS_FOR_PROGRAM: '/hubs/:id/volunteer_match_stats/:program_id',
  GET_PAYMENT_PACKAGE_STATS: '/hubs/:id/payment_package/:package_id/stats',
  APPROVE_VOLUNTEER_MATCH: '/hubs/:id/approve_volunteer_match/:program_id/:logged_hours_id',
  BULK_APPROVE_VOLUNTEER_MATCH: '/hubs/:id/approve_volunteer_match/:program_id',
  REJECT_VOLUNTEER_MATCH: '/hubs/:id/reject_volunteer_match/:program_id/:logged_hours_id',
  BULK_REJECT_VOLUNTEER_MATCH: '/hubs/:id/reject_volunteer_match/:program_id',
  CREATE_HUB_PAYMENT_PACKAGE: '/hubs/:id/payment_package',
  GENERATE_HUB_PAYMENT_PACKAGE:
    '/donations/donation_matching_programs/for/hub/:object_id/:program_id/generate_package',
  GET_HUB_PAYMENT_PACKAGE: '/hubs/:id/payment_package/:package_id',
  GET_HUB_PAYMENT_PACKAGES: '/hubs/:id/payment_packages',
  PAY_HUB_PAYMENT_PACKAGE: '/hubs/:id/payment_package/:package_id/:group_id/pay',
  GET_USER_CREDIT_BALANCE: '/users/current/credit_balance/total',
  GET_USER_CREDIT_BALANCE_FOR_GROUP: '/users/current/credit_balance/:group_id',
  DONATE_WITH_CREDITS: '/donations/donate_with_credits/:group_id',

  GENERATE_VERIFICATION_CODE: '/auth/generate_verification_token/:email',
  VALIDATE_VERIFICATION_CODE: '/auth/validate_verification_code/:email/:code',
  CREATE_SUBSCRIPTION_FOR_GROUP: '/billing/subscriptions/for_group/:group_id',
  GET_SUBSCRIPTIONS_FOR_GROUP: '/billing/subscriptions/for_group/:group_id',
  CANCEL_SUBSCRIPTION_FOR_GROUP: '/billing/subscriptions/for_group/:group_id/:subscription_code',
  GET_GROUP_SUBSCRIPTION_HISTORY: '/billing/subscriptions/:group_id/:subscription_code/history',
  GET_HUB_SUBSCRIPTION_HISTORY: '/billing/subscriptions/for_hub/:hubId/:subscriptionCode/history',
  GET_AVAILABLE_GROUP_SUBSCRIPTIONS: '/billing/subscriptions/available_subscriptions/:type',
  GET_PREMIUM_FEATURE_SUMMARY: '/billing/for/:object_type/:object_id/features_summary',
  GET_METRICS_FOR_OBJECT_TYPE: '/metrics/available_metrics/:objectType',
  GET_METRIC: '/metrics/metric',
  GET_METRICS_KPI: '/metrics/kpi',
  GET_METRICS_KPI_BULK: '/metrics/kpis/kpis_bulk',
  GET_METRIC_BULK: '/metrics/metrics_bulk',
  CREATE_STORY: '/activity_feed/stories/for/:entity_type/:id',
  DELETE_STORY: '/stories/for/:entity_type/:id/:storyId',
  GET_USER_STORIES: '/activity_feed/stories/for/user/:id',
  GET_STORIES: '/activity_feed/stories',
  GET_STORIES_FOR_ENTITY: '/activity_feed/stories/for/:entityType/:entityId',
  GET_USER_HUB_ROLE: '/users/current/hubs/:hub_id/role',
  GET_USER_SPOTLIGHTS: '/users/current/my_spotlight',
  GET_USER_MATCHING_PROGRAMS: '/users/current/donation_matching_programs',
  GET_USER_PAGES_FOLLOWING: '/users/current/following',
  GET_USER_HAS_VOLUNTEER_FOR_DOLLARS: '/users/current/has_hub_with_credits',
  DELETE_HUB_PARTNERS: '/hubs/:id/groups',
  GET_CONTACT_SUMMARY: '/users/contacts/status_summary/:contactId',
  GET_MY_CONTACTS: '/users/contacts/my_contacts',
  REQUEST_CONTACT: '/users/contacts/requests',
  ACCEPT_CONTACT_REQUEST: '/users/contacts/requests/:contactId/accept',
  REJECT_CONTACT_REQUEST: '/users/contacts/requests/:contactId/reject',
  ADD_ORGANIZER_CONTACT: '/users/contacts/add_organizer_contact',
  GET_ORGANIZER_CONTACTS: '/users/contacts/organizers/for/:object_type/:object_id',
  GET_AVAILABLE_ORGANIZER_CONTACTS:
    '/users/contacts/available_organizer_contacts/for/:object_type/:object_id',
  REMOVE_CONTACT: '/users/contacts/:contactId',
  GET_UNREAD_CONVERSATIONS_SUMMARY: '/chat/conversations/unread',
  MARK_CONVERSATION_AS_READ: '/chat/conversations/:id/mark_read',
  GET_CONVERSATION_USERS: '/chat/conversations/:id/users',
  GET_USER_HUBS_FOR_PERMISSION: '/users/current/hubs_for_permission/:permission',
  GET_ONLINE_USERS: '/chat/online',
  GET_MEMBER_STORIES: '/activity_feed/stories/for/:entity_type/:id/member_stories', // will be used for multiple entities.
  GET_USER_PROFILE_PAGES: '/users/:id/pages',
  GET_USER_OBJECT_FOLLOWING: '/users/current/following/:objectType',
  GET_USER_HUB_OBJECTS: '/users/current/myhubs/:objectType',
  UPDATE_HUB_STATUS: '/hubs/:id/status',
  DISCOVER_GROUPS_BASE: '/groups/discover',
  CANCEL_RECURRING_PAYMENT: '/groups/:id/subscriptions/:transaction_id/cancel',

  GET_CAMPAIGN_STRIPE_PAYOUTS: '/groups/:groupId/campaigns/:campaignId/payouts',
  GET_CAMPAIGN_PAYOUT_TRANSACTIONS:
    '/groups/:groupId/campaigns/:campaignId/payouts/:payoutId/transactions',
  GET_CAMPAIGN_PAYOUT_KPI: '/groups/:groupId/campaigns/:campaignId/payouts/:payoutId/kpi',
  GET_PAYOUTS: '/groups/:groupId/accounts/:accountId/payouts',

  PUT_HUB_SUBSCRIPTION_END_TRIAL:
    '/billing/subscriptions/for_hub/:hub_id/:subscription_code/end_trial',
  UPDATE_HUB_SUBSCRIPTION_ADMIN: '/billing/subscriptions/for_hub/:hub_id/:subscription_code/admin',

  // TODO [!]: This should be removed since account will be on group.
  GET_GROUP_CAMPAIGN_STRIPE_ACCOUNT_LINK: '/groups/:groupId/campaigns/:campaignId/account_link',
  GET_GROUP_STRIPE_ACCOUNT_AUTHORIZE_LINK: '/groups/:groupId/account_authorization_link',
  GET_GROUP_STRIPE_ACCOUNT_LINK: '/groups/:groupId/account_link/:accountId',
  GET_GROUP_ACCOUNTS: '/groups/:groupId/accounts',
  GET_GROUP_ACCOUNTS_WITH_DETAILS: '/groups/:groupId/accounts/details',
  CREATE_GROUP_EXTERNAL_ACCOUNT: '/groups/:groupId/accounts/:accountId/external_accounts',
  GET_GROUP_EXTERNAL_ACCOUNT_DEFAULT:
    '/groups/:groupId/accounts/:accountId/external_accounts/default',
  SET_GROUP_ACCOUNT_CURRENT: '/groups/:groupId/accounts/:accountId/set_current',

  VOLUNTEER_FOR_GIG_VM: 'gigs/:gigId/volunteerMatchApply',

  GET_GROUP_DONATION_PAYMENT_METHOD_DETAILS:
    '/groups/:groupId/donations/:transactionId/payment_method_details',
  GET_EVENT_DONATION_PAYMENT_METHOD_DETAILS:
    '/events/:eventId/donations/:transactionId/payment_method_details',

  CONNECT_USER_STRIPE: '/users/current/account_link',

  GET_USER_GIG_SHIFTS: '/users/current/gig_shifts',
  GET_USER_COMPANY_GIG_APPLICATIONS: '/users/current/applications/:hubId/hub_gig_applications',

  GET_HUB_CAUSES: '/hubs/:id/causes',

  GROUP_PAYMENT_SUMMARY: '/payments/for/group/:id/payment_summary',
  GET_ALL_SUBSCRIPTIONS_SUPER_ADMIN: '/billing/subscriptions/:object_type',
  START_HUB_TRIAL: '/billing/subscriptions/for_hub/:hub_id/:subscription_code/start_trial',
  GET_HUB_LOCATIONS: '/hubs/:id/locations',
  GET_HUB_DONATION_MATCHING_PROGRAMS: '/donations/donation_matching_programs/for/hub/:object_id',
  GET_HUB_DONATION_MATCHING_PROGRAM_BY_ID:
    '/donations/donation_matching_programs/for/hub/:object_id/:program_id',
  GET_GROUPS_FOR_DONATION_MATCHING_PROGRAM:
    '/donations/donation_matching_programs/for/hub/:object_id/:program_id/groups',
  GET_AVAILABLE_DONATION_MATCHING_PROGRAMS:
    '/donations/donation_matching_programs/available_programs/:program_type',
  GET_AVAILABLE_DONATION_MATCHING_PROGRAMS_FOR_TRANSACTION:
    '/donations/donation_matching_programs/available_programs/:program_type/:transaction_id',
  APPROVE_DONATION_MATCHING:
    '/donations/donation_matching_programs/for/hub/:object_id/:program_id/approve_match/:transaction_id',
  DECLINE_DONATION_MATCHING:
    '/donations/donation_matching_programs/for/hub/:object_id/:program_id/decline_match/:transaction_id',
  DELETE_HUB_LOCATION: '/hubs/:id/locations/:locationId',
  CREATE_HUB_LOCATION: '/hubs/:id/locations',
  GROUP_SEARCH_V2: '/search/groups',
  EVENT_SEARCH_V2: '/search/events',
  GIG_SEARCH_V2: '/search/gigs',
  GET_HUB_SPOTLIGHTS: '/hubs/:id/spotlights',
  GET_HUB_DONATIONS_BY_ID: '/donations/for/hub/:hubId',
  GET_HUB_DONATIONS: '/hubs/:id/donations',
  GET_HUB_DONATIONS_BETWEEN_DATES: '/hubs/:id/donations/:start_date/:end_date',
  GET_MEMBER_APPLICATION_SUMMARY: '/hubs/:id/member_application_summary',
  GET_MEMBER_APPLICATION_SUMMARY_EXPORT: '/hubs/:id/member_application_summary/export',
  GET_HUB_DONATION_PAYMENT_METHOD_DETAILS:
    '/donations/for/hub/:hub_id/for_child/:child_object_type/:child_object_id/transaction/:transaction_id/payment_method_details',
  GET_RECOMMENDED_GROUPS: '/groups/recommendedCauses',
  UPDATE_RECOMMENDED_GROUPS: '/groups/setRecommendedCauses',
  REMOVE_RECOMMENDED_GROUPS: '/groups/removeRecommendedCauses',
  CREATE_OFFLINE_HUB_DONATION: '/donations/for/hub/:object_id/offline_donations/corporate',
  CREATE_OFFLINE_USER_DONATION: '/donations/for/user/current/offline_donations',
  UPDATE_OFFLINE_DONATION: '/donations/offline_donations/:transaction_id',
} as const; // `as const` narrows the type to the exact string. Gives us intellisense for each route.
// May cause some type weirdness due to this "narrowing". Just remove `as const` if so.
// You can also append `as string` to any assignment of `routes.X` to a variable to avoid type issues.

/**
 * @deprecated Use formatQuery() instead.
 */
export function formatFilters(filters: IActiveFilter[]) {
  let _format: string = '';

  for (let i = 0; i < filters.length; i++) {
    if (filters[i].type === 'text') {
      _format += 'field_filters[' + filters[i].id + ']' + '=%25' + filters[i].value + '%25';
    } else if (filters[i].type === 'dropdown') {
      _format += 'field_filters[' + filters[i].id + ']' + '=' + filters[i].value;
    } else if (filters[i].type === 'number') {
      _format +=
        'field_filters[' + filters[i].id + ']' + '=' + filters[i].prefix + filters[i].value;
    }

    if (i < filters.length - 1) {
      _format += '&';
    }
  }

  return _format;
}

/**
 * These fields generally match whats in 'query.interface.ts' There's also some good documentation in there.
 *
 * The options [search, populate, flags, pages_type,field_filters, client_filters, common_filters, type] all require
 * backend to be implemented for it to work properly.
 */
export interface IQueryParams {
  /** Provide a string to wildcard search on predefined fields, these have to be
   * predefined in the schema you're trying to search.
   */
  search?: string;
  /** Provide an array of fields you want to sort by
   * e.g [{ id: 'field_name', order: 'desc' }]
   */
  sort?: ISortSettings[];
  /** Used for complex field filtering. Should be key/value pair format, where the key is the field to filter and the value is the filter to apply.
   * The operator applied in the query can be changed. The following operators are supported:
   * - Equal: =100
   * - Not Equal: <>100
   * - Less Than: <100
   * - Less Than or Equal: <=100
   * - Greater Than: >100
   * - Greater Than or Equal: >=100
   * - Wildcards: %ABC%
   *
   * Wildcards can be placed anywhere in the query string to allow complex matching.
   * When no operator is provided, query is treated as a wildcard search.
   *
   * When querying from API, this is how field_filters should be formatted: /groups/donors?field_filters[total_donated]=<100&field_filters[custom_fields.Forecasted Donor Value]=>=500
   *
   * Custom fields can be filtered using: custom_fields.{custom field name}.
   *
   * Multiple query values can be specified using pipe (|) as a separator like so: volunteer|supporter.
   *
   */
  filters?: IActiveFilter[];
  client_filters?: {
    id: string;
    value: string;
  }[];
  /** Limits the results returned, this should be a number  */
  limit?: string;
  /** Used in pagination, should be a number. This is used to skip an amount of records to get
   * your next 'page'/'set' of data.
   */
  skip?: string;

  //The below properties seem to be for very specific scenarios. I'd try to avoid using them.
  charities?: string;
  common_filters?: {
    causes: string[];
  };
  type?: string;

  /** @deprecated This is used only for findPayouts account.controller.findPayouts */
  start_after?: string;
}

/** Formats a query object into a URLSearchParams object so it can be used in a GET list call. */
export function formatQuery(params: IQueryParams): URLSearchParams {
  let urlParams = new URLSearchParams();

  if (params.search) {
    urlParams.append('search', params.search);
  }

  if (params.sort) {
    urlParams.append('sort', formatSorting(params.sort));
  }

  if (params.limit) {
    urlParams.append('limit', params.limit);
  }

  if (params.start_after) {
    urlParams.append('start_after', params.start_after);
  }

  if (params.skip) {
    urlParams.append('skip', params.skip);
  }

  if (params.charities) {
    urlParams.append('charities', params.charities);
  }

  if (params.type) {
    urlParams.append('type', params.type);
  }

  if (params.filters) {
    urlParams = appendFilterToUrlParmas(urlParams, params.filters);
  }

  if (params.common_filters?.causes) {
    for (let filter of params.common_filters.causes) {
      urlParams.append(`common_filters[causes]`, filter);
    }
  }

  if (params.client_filters) {
    for (let filter of params.client_filters) {
      urlParams.append(`client_filters[${filter.id}]`, filter.value);
    }
  }

  return urlParams;
}

export function appendFilterToUrlParmas(urlParams: URLSearchParams, filterParams: IActiveFilter[]) {
  for (let filter of filterParams) {
    let filterValue = filter.value;

    switch (filter.type) {
      case 'text':
        filterValue = `%${filter.value}%`;
        break;
      case 'with_prefix':
      case 'number':
      case 'date':
        filterValue = filter.prefix + filter.value;
        break;
      case 'dropdown':
      case 'flag':
        filterValue = `${filter.value}`;
        break;
    }

    //If the filter already exists, append it to the existing value. E.g (field_filters[total_donated]=>=100&<=200)
    if (urlParams.has(`field_filters[${filter.id}]`)) {
      const existingFieldFilter = urlParams.get(`field_filters[${filter.id}]`);
      urlParams.set(`field_filters[${filter.id}]`, `${existingFieldFilter}&${filterValue}`);
    } else {
      urlParams.append(`field_filters[${filter.id}]`, filterValue);
    }
  }

  return urlParams;
}

export function formatSorting(sortSettings: ISortSettings[]) {
  let sortString: string = '';

  for (let i = 0; i < sortSettings.length; i++) {
    if (sortSettings[i].order === 'desc') {
      sortString = sortString + '-' + sortSettings[i].id;
    } else {
      sortString = sortString + sortSettings[i].id;
    }

    if (i < sortSettings.length - 1) {
      sortString = sortString + ',';
    }
  }

  return sortString;
}

/**
 * @deprecated Use formatQuery() instead.
 */
export function sortColumn(sortSettings: ISortSettings[], callback?: (sort: string) => void) {
  let _sort: string = '';

  for (let i = 0; i < sortSettings.length; i++) {
    if (sortSettings[i].order === 'desc') {
      _sort = _sort + '-' + sortSettings[i].id;
    } else {
      _sort = _sort + sortSettings[i].id;
    }

    if (i < sortSettings.length - 1) {
      _sort = _sort + ',';
    }
  }

  if (callback) {
    callback(_sort);
  }
}

// TODO: We should look to remove this in future. With react hooks, this isn't as nessassary.
// TODO: Add proper types to this.
export function handleInputChange(
  event: any,
  self: any,
  prevent?: boolean,
  callback?: any,
  passCbVal?: boolean,
) {
  // TODO: Figure out why this is needed? and leave a comment here.
  if (prevent === true) {
    event.preventDefault();
  }

  const target = event.target;
  let value =
    target.type === 'checkbox'
      ? target.checked
      : target.type === 'radio'
        ? target.id
        : target.value;

  if (target.type === 'tel') {
    //validate input if it's a phone number
    const passValidation = new RegExp(
      "^(\\+)?(?:([])(?:([0-9]+)([-\\' '])?([0-9]+)?([-\\' '])?([0-9]+)?([-\\' '])?([0-9]+)?|)|[0-9]+([-\\' '])?([0-9]+)?([-\\' '])?([0-9]+)?([-\\' '])?([0-9]+)?)?$",
    ).test(target.value);
    if (passValidation) {
      value = target.value;
    } else {
      return;
    }
  }

  const name = target.name;

  self.setState(
    {
      [name]: value,
    },
    () => {
      if (callback !== undefined) {
        if (passCbVal) {
          callback(value);
        } else {
          callback();
        }
      }
    },
  );
}

let searchTimeout: ReturnType<typeof setTimeout>;

export function handleDebounce(value: any, delay: number = 500) {
  if (searchTimeout) {
    clearTimeout(searchTimeout);
  }

  return new Promise((resolve, reject) => {
    searchTimeout = setTimeout(() => {
      resolve(value);
    }, delay);
  });
}

/** Swaps all route params formatted like: `:param` with a value from an object.
 */
export function swapRouteParams(
  route: string,
  params: Record<string, string | number | undefined | null>,
) {
  let newRoute = route;

  for (let p in params) {
    if (p !== undefined && route.includes(p)) {
      newRoute = newRoute.replace(':' + p, params[p] as string);
    }
  }

  return newRoute;
}

export function dataURItoBlob(dataURI: any) {
  let byteString = atob(dataURI.split(',')[1]);

  let mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

  let ab = new ArrayBuffer(byteString.length);
  let ia = new Uint8Array(ab);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  return new Blob([ab], { type: mimeString });
}

export async function uploadImageToStore(img: string) {
  const blob = dataURItoBlob(img);
  let formData = new FormData();
  formData.append('file', blob);

  const response = await axios.post<{ value: string }>(
    Config.web.REACT_APP_IMAGE_API + routes.UPLOAD,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  );
  return response.data.value;
}

export function mapPermissions(permissions: any) {
  let _permissions: IStringMap = {};

  for (let p in permissions) {
    _permissions[permissions[p].permission] = permissions[p].value;
  }

  return _permissions;
}

export function hasPermission(name: string, role: IRole | null) {
  return (
    role?.permissions?.find((perm) => {
      return perm.permission === name && perm.value === true;
    }) != null
  );
}

/** Creates a toast success object. Should be passed to createToast() in actions/toaster.tsx
 * @param translatedMessage IMPORTANT: This argument MUST!! be translated by the caller.
 * @param untranslatedTitle IMPORTANT: This argument MUST NOT!! be translated by the caller.
 */
export function toastSuccess(
  translatedMessage: string,
  untranslatedTitle: string,
  actionList?: IToastAction[],
): IToast {
  return {
    toastType: 'success',
    message: translatedMessage,
    title: untranslatedTitle,
    icon: 'fad fa-check',
    actionList,
  };
}

/** Creates a toast info object. Should be passed to createToast() in actions/toaster.tsx
 * @param translatedMessage IMPORTANT: This argument MUST!! be translated by the caller.
 * @param untranslatedTitle IMPORTANT: This argument MUST NOT!! be translated by the caller.
 */
export function toastInfo(
  translatedMessage: string,
  untranslatedTitle: string,
  actionList?: IToastAction[],
): IToast {
  return {
    toastType: 'info',
    message: translatedMessage,
    title: untranslatedTitle,
    icon: 'fad fa-exclamation-circle',
    actionList,
  };
}

/** Creates a toast error object. Should be passed to createToast() in actions/toaster.tsx
 * @param translatedMessage IMPORTANT: This argument MUST!! be translated by the caller.
 * @param untranslatedTitle IMPORTANT: This argument MUST NOT!! be translated by the caller.
 */
export function toastError(
  translatedMessage: string,
  untranslatedTitle: string,
  actionList?: IToastAction[],
): IToast {
  return {
    toastType: 'error',
    message: translatedMessage,
    title: untranslatedTitle,
    icon: 'fad fa-exclamation-circle',
    actionList,
  };
}

//TODO we need to switch our code to use this function every time when we want to download .CSV file
//
/** Creates and downloads file from BLOB data
 * @param blobData BLOB data from API response
 * @param fileName File name without extension
 * @param mime File type ''
 */
export function downloadFile(fileName: string, blobData: string, mime: string = 'text/csv'): void {
  let blob = new Blob([blobData], { type: mime });

  let file = URL.createObjectURL(blob);
  let a = document.createElement('a');
  a.href = file;
  a.download = fileName;
  document.body.appendChild(a);
  a.click();
}

/**
 * // TODO: Replace formatAddressLine implementations with getAddressParts
 * @deprecated Use getAddressParts instead.
 */
export function formatAddressLine(item: any) {
  let _formattedAddress = '';

  if (item.line1) {
    _formattedAddress += item.line1;
  }

  if (item.line2) {
    _formattedAddress += ', ' + item.line2;
  }

  if (item.city) {
    _formattedAddress += ', ' + item.city;
  }

  if (item.state) {
    _formattedAddress += ', ' + item.state;
  }

  if (item.country) {
    _formattedAddress += ', ' + item.country;
  }

  if (item.postal_code) {
    _formattedAddress += ', ' + item.postal_code;
  }

  return _formattedAddress;
}

/** Takes an entity location and key list to return a string of comma separated address parts
 * @param location the location from the entity to parse
 * @param addressParts - An array of strings (must be key of IAddressBase) to index on location.
 * @returns string
 */
type IAddressBaseKeys = keyof IAddressBase;
export function getAddressParts(location: IAddressBase, addressParts: IAddressBaseKeys[]): string {
  return addressParts
    .map((addressPart) => location[addressPart] ?? null)
    .join(', ')
    .trim();
}

export async function uploadImage(img: string, accessToken?: string) {
  const blob = dataURItoBlob(img);
  let formData = new FormData();
  formData.append('file', blob);

  let headers: { [index: string]: string } = {
    'Content-Type': 'multipart/form-data',
  };

  if (accessToken) {
    headers['Authorization'] = `Bearer ${accessToken}`;
  }

  let resp = await axios
    .post(Config.web.REACT_APP_IMAGE_API + routes.UPLOAD, formData, {
      headers: headers,
    })
    .then((response) => {
      return response.data.value;
    });

  return resp;
}

/** Uploads one or more Medias to Storage.
 * @returns Array of media items which contain the urls of the uploaded objects.
 */
export async function uploadMedias(mediaBlobs: string[]): Promise<IMediaItem[]> {
  const medias: IMediaItem[] = [];

  for (let blob of mediaBlobs) {
    if (blob.includes('data:image')) {
      const result = await uploadImage(blob);
      medias.push({
        url: result,
      });
    } else {
      medias.push({
        url: blob,
      });
    }
  }

  return medias;
}

export function timeSince(timestamp: number | Date | Moment): { interval: number; unit: string } {
  let seconds = Math.floor(moment().unix() - parseInt(moment(timestamp).format('x')));
  let interval = seconds / 31536000;

  if (interval > 1) {
    return { interval: Math.floor(interval), unit: 'year' };
  }

  interval = seconds / 2592000;

  if (interval > 1) {
    return { interval: Math.floor(interval), unit: 'month' };
  }

  interval = seconds / 86400;

  if (interval > 1) {
    return { interval: Math.floor(interval), unit: 'day' };
  }

  interval = seconds / 3600;

  if (interval > 1) {
    return { interval: Math.floor(interval), unit: 'hour' };
  }

  interval = seconds / 60;

  if (interval > 1) {
    return { interval: Math.floor(interval), unit: 'minute' };
  }

  return { interval: Math.floor(interval), unit: 'second' };
}

/** Combines one or more CSS class names. Null or undefined values are ignored.
 */
export function combineClassNames(...classNames: Array<string | null | undefined>) {
  if (classNames.length === 0) {
    return undefined;
  }

  return classNames.filter((className) => className !== undefined && className !== null).join(' ');
}

/** Returns only the text from a Quill markup string.
 * @example <p>test</p> -> test
 */
export function extractLettersFromQuillMarkup(msg: string): string {
  return msg.replace(/<(.|\n)*?>/g, '');
}

/** Replaces the given list at the given index.
 * @returns A new array with the item replaced.
 */
export function replaceAtIndex(list: any[], index: number, obj: any) {
  if (index === -1) {
    throw new Error(`Cannot replace item at index -1`);
  }

  let newArray = [...list];

  newArray[index] = obj;

  return newArray;
}

/** Default currency used for reporting. */
// TODO: Move to `localeConstants`
export const defaultCurrency = 'CAD';

// -----------------
/** Formats a currency.
 *
 * Example: $2,500.00
 *
 * Note: Copied from @gigit/common.
 * Copied because @gigit/common cannot be imported to FE since @gigit/common references nestjs libraries (Causes this error: Module not found: Can't resolve 'cache-manager').
 */
export function formatCurrency(
  amount: number | null | undefined,
  currency: string,
  locale?: string,
  options?: { decimal_places?: number; numberFormatOptions?: Intl.NumberFormatOptions },
): string {
  let formatter = new Intl.NumberFormat(locale || Constants.default_locale, {
    style: 'currency',
    currency: currency.toUpperCase(),
    minimumFractionDigits: options?.decimal_places ?? 2,
    maximumFractionDigits: options?.decimal_places ?? 2,
    ...options?.numberFormatOptions,
  });

  return formatter.format(round(amount || 0, options?.decimal_places ?? 2));
}

export function formatNumberCompactNotation(
  value: number,
  locale?: string,
  options?: Intl.NumberFormatOptions,
) {
  let formatter = new Intl.NumberFormat(locale || Constants.default_locale, {
    maximumFractionDigits: 1,
    ...options,
    notation: 'compact',
  });

  return formatter.format(value);
}

// -----------------
/** Rounds a number to the given decimals.
 *
 * Note: Copied from @gigit/common.
 * Copied because @gigit/common cannot be imported to FE since @gigit/common references nestjs libraries (Causes this error: Module not found: Can't resolve 'cache-manager').
 */
export function round(value: number, decimals: number): number {
  // @ts-ignore
  return +(Math.round(value + 'e+' + decimals) + 'e-' + decimals);
}

export function alphabetizeOptionsList(list: Array<IOptions>) {
  return list.sort((a: IOptions, b: IOptions) => {
    return a.label.localeCompare(b.label);
  });
}

/** Capitalizes the first letter of the provided text. */
export function capitalizeString(text: string | null | undefined) {
  if (!text) {
    return text;
  }

  if (text.length >= 2) {
    let splitStr = text.toLowerCase().split(' ');
    return splitStr
      .map((s) => {
        return s.charAt(0).toUpperCase() + s.substring(1);
      })
      .join(' ');
  } else {
    return text.toUpperCase();
  }
}

//This defines a general mobile width,
//in some cases it may be required to change this based on the UI being created
export function isMobileScreen(width?: number) {
  return window.innerWidth <= (width ?? 680);
}

/** Event statuses that can be manually assigned by an admin */
export const assignableEventStatuses = Object.values(Constants.event_status).filter(
  (e_stat) => e_stat !== Constants.event_status.completed,
); //statuses running and completed will be assigned by cron work automatically

export interface ISetSEOMetaTags {
  urlPath?: string;
  title?: string;
  description?: string;
  imageURL?: string;
  coverImageURL?: string;
}
/**
 * Sets metatags for open graph and twitter so that prerender.io can cache it.
 * Note: You'll have to set "(window as any).prerenderReady = false;" in componentDidMount;
 *
 * @param { ISetSEOMetaTags } SeoMetatags Object
 * @param SeoMetatags.urlPath Path to component after base URL -> `${Config.web.REACT_APP_BASE_URL}/${urlPath}`
 * @param SeoMetatags.title Sets the title metatags for og:title, twitter:title, and <title></title>
 * @param SeoMetatags.description Sets the description metatags for og:description and twitter:description
 * @param SeoMetatags.imageURL Sets the image metatags og:image and twitter:image
 * @param SeoMetatags.coverImageURL Sets the twitter:card image
 *
 * @example From Group.tsx ->
 * setSEOMetatags({
 *       urlPath: `group/${this.props.groupState.group.handle}`,
 *       title: this.props.groupState.group.title,
 *       description: this.props.groupState.group.description,
 *       imageURL: this.props.groupState.group.profile_image_url,
 *       coverImageURL: this.props.groupState.group.cover_image_url
 * });
 */
export const setSEOMetatags = (seoMetatags: ISetSEOMetaTags) => {
  const { title, description, urlPath, imageURL, coverImageURL } = seoMetatags;
  //Sets open graph metatags
  if (title) {
    document.title = title;
    document?.querySelector('meta[property="og:title"]')?.setAttribute('content', title);
  }
  if (description)
    document
      ?.querySelector('meta[property="og:description"]')
      ?.setAttribute('content', description);
  if (imageURL)
    document?.querySelector('meta[property="og:image"]')?.setAttribute('content', imageURL);
  if (urlPath)
    document
      ?.querySelector('meta[property="og:url"]')
      ?.setAttribute('content', `${Config.web.REACT_APP_BASE_URL}/${urlPath}`);
  //Sets twitter metatags
  if (title) document?.querySelector('meta[name="twitter:title"]')?.setAttribute('content', title);
  if (coverImageURL)
    document?.querySelector('meta[name="twitter:card"]')?.setAttribute('content', coverImageURL);
  if (description)
    document
      ?.querySelector('meta[name="twitter:description"]')
      ?.setAttribute('content', description);
  if (imageURL)
    document?.querySelector('meta[name="twitter:image"]')?.setAttribute('content', imageURL);

  (window as any).prerenderReady = true;
};

export const formatCardIcon = (brand: string): string => {
  let _brand = 'fab fa-cc-' + brand;

  if (brand === 'diners') {
    _brand = 'fab fa-cc-diners-club';
  }

  if (brand === 'unionpay') {
    _brand = 'fas fa-credit-card';
  }

  if (brand === 'google_pay') {
    _brand = 'fab fa-google-pay';
  }

  if (brand === 'apple_pay') {
    _brand = 'fab fa-cc-apple-pay';
  }

  return _brand;
};

/**
 * Used to strip html from strings when not displaying in quill
 * @param html
 */
export const stripHtml = (html: string) => {
  const tmp = document.createElement('DIV');
  tmp.innerHTML = html;
  return tmp.textContent || tmp.innerText || '';
};

/**
 * Used to conditionally insert elements into an array
 * @example
 * const arrayOfObjects = [
*      ...insertIf(someValue === otherValue, {
            random: "random"
        }),
        { random2: "random2"}
    ]
 * @param condition Condition to be met
 * @param elements Elements you want to be inserted into the array
 * @returns Returns elements when the condition is met
 */
export function insertIf<T>(condition: boolean, ...elements: T[]) {
  return condition ? elements : [];
}

/** Picks white or black color based on background color */
export function getTextColorBasedOnBgColor(bgColor: string) {
  const lightColor = '#FFFFFF';
  const darkColor = '#001f2c';

  const color = bgColor.charAt(0) === '#' ? bgColor.substring(1, 7) : bgColor;
  const r = parseInt(color.substring(0, 2), 16); // hexToR
  const g = parseInt(color.substring(2, 4), 16); // hexToG
  const b = parseInt(color.substring(4, 6), 16); // hexToB
  const colors = [r / 255, g / 255, b / 255];
  const c = colors.map((col) => {
    if (col <= 0.03928) {
      return col / 12.92;
    }
    return Math.pow((col + 0.055) / 1.055, 2.4);
  });
  const L = 0.2126 * c[0] + 0.7152 * c[1] + 0.0722 * c[2];

  return L > 0.179 ? darkColor : lightColor;
}

export const lorumIpsumSamples = {
  /** 300 Characters */
  standard:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cil.',
};

/**
 * Reusable styles for React Joyride
 */
export const joyRideStyles = {
  options: {
    arrowColor: '#FFF',
    backgroundColor: '#FFF',
    overlayColor: 'rgba(0, 0, 0, 0.5)',
    primaryColor: '#5E51AB',
    textColor: '#505050',
  },
};

/**
 * @description Generate empty card
 * @param value - how many empty cards to add
 * @param className - custom class for empty card
 */
export function generateEmptyCards(value: number, className?: string) {
  let emptyCards = [] as ReactNode[];

  for (let i = 0; i < value; i++) {
    emptyCards.push(
      <div
        key={i}
        className={`empty-card ${className || ''}`}
      />,
    );
  }

  return emptyCards;
}

export function getUnreadChatCount(conversations: IConversationSummary[], user_id: string) {
  return (
    conversations
      .filter((cs) => {
        return (
          cs?.read_states &&
          cs?.read_states?.filter((rs) => {
            return !rs.read && rs.user_id === user_id;
          })?.length > 0
        );
      })
      .map((cs) =>
        cs.read_states
          ?.map((rs) => rs.unread_count)
          .reduce(function (a, b) {
            return a + b;
          }, 0),
      )
      .reduce(function (a, b) {
        return (a || 0) + (b || 0);
      }, 0) || 0
  );
}

export function isURL(str: string) {
  const urlRegex =
    '^(?!mailto:)(?:(?:http|https|ftp)://)(?:\\S+(?::\\S*)?@)?(?:(?:(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[0-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,})))|localhost)(?::\\d{2,5})?(?:(/|\\?|#)[^\\s]*)?$';
  const url = new RegExp(urlRegex, 'i');
  return str.length < 2083 && url.test(str);
}

export function isPageActive(params: {
  status?: string;
  roleName: string;
  isUserLoggedIn: boolean;
}) {
  const { roleName, isUserLoggedIn } = params;
  const allowedRoles = [
    uiConstants.role.admin,
    uiConstants.role.onBehalfOf,
    uiConstants.role.member,
  ];
  const isUserAllowed = isUserLoggedIn && roleName && allowedRoles.includes(roleName);

  return isUserAllowed;
}

export function focusOnInputField(ref: RefObject<HTMLInputElement>) {
  if (ref?.current) {
    ref?.current.focus();
  }
}

export function donationShowError(params: {
  owner: IOwnerObject<OwnerObjectTypes>;
  error: string;
  hasBeenTranslated?: boolean;
  options?: { errorCode?: string | null };
  isAdmin?: boolean;
  fireToast(toast: IToast): void;
}) {
  const { owner, error, fireToast, hasBeenTranslated, options, isAdmin } = params;

  let toast: IToast;

  if (options?.errorCode === 'ERROR.EXTERNAL.STRIPE_ACCOUNT_CHARGES_DISABLED') {
    const message = isAdmin
      ? localizeHelpers.translate(
          'We require just a few more details before you can start accepting payments. Click here to finish your setup.',
        )
      : localizeHelpers.translate(
          'This page is not currently setup for payments.  Please contact the organization or administrator',
        );
    const actionList = isAdmin
      ? [
          {
            link: {
              link: `/${uiConstants.ownerType.group}/${typeHelpers.getGroupHandleFromOwner(owner)}/admin?t=balance_and_payouts`,
              text: localizeHelpers.translate('Finish Setup'),
            },
          },
        ]
      : undefined;

    toast = toastError(message, 'Donation Error', actionList);
  } else {
    toast = toastError(
      hasBeenTranslated ? error : localizeHelpers.translate(error),
      'Donation Error',
    );
  }

  fireToast(toast);
}

/** Formats a username. Handles different scenarios of username combinations (ie: display_name for corporate, first,middle,last for persons) */
export function formatUsername(user?: {
  first_name?: string;
  last_name?: string;
  display_name?: string;
  middle_name?: string;
}) {
  if (user?.middle_name) return user?.first_name + ' ' + user?.middle_name + ' ' + user?.last_name;
  else if (user?.first_name) return user?.first_name + ' ' + user?.last_name;
  else return user?.display_name;
}
